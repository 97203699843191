import { createApp } from 'vue'
import App from './App.vue'
// import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/global.scss';

import i18n from './i18n'

/* Global Functions */
import globalFunctions from "./functions/global.js"

/* Axios */
import axios from 'axios'
import VueAxios from 'vue-axios'

/* Vue Tel Input */
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

/* Vue Select Component */
import Select2 from 'vue3-select2-component';

/* Vue Select Search Component */
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// import Vue Svg Inline Plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import "vue-svg-inline-plugin/src/polyfills";

// Vue cookies
import VueCookies from 'vue-cookies'

const app = createApp(App)
    .use(VueSvgInlinePlugin)
    .use(VueCookies, {})
    .use(i18n)
    .use(IonicVue)
    .use(VueAxios, axios)
    .use(globalFunctions)
    .use(VueTelInput)
    .component('v-select', Select2)
    .component('v-search-select', vSelect)
    .component('Datepicker', Datepicker)
// .use(router);

/* Global Config */
import config from "./config"
app.config.globalProperties.$config = config;

/* i18n */
app.config.globalProperties.$i18n = i18n

/* Moment.js */
import moment from "moment-timezone";
import skLocale from "moment/locale/sk";
skLocale.relativeTime = {
    future: 'Za %s',
    past: 'Pred %s',
    s: '%ds',
    ss: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    w: '%dt',
    ww: '%dt',
    M: '%dm',
    MM: '%dm',
    y: '%dr',
    yy: '%dr',
}
moment.updateLocale('sk', skLocale)
app.config.globalProperties.$moment = moment;

/* Vuex */
// import store from './store'
// app.config.globalProperties.$store = store;

/* Ion Components */
import { IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonHeader, IonCheckbox, IonTextarea, IonRadioGroup, IonRadio, IonIcon, IonSelect, IonSelectOption, IonAccordion, IonAccordionGroup, IonSpinner, IonPage } from "@ionic/vue"
app.component('ion-content', IonContent);
app.component('ion-list', IonList);
app.component('ion-item', IonItem);
app.component('ion-label', IonLabel);
app.component('ion-button', IonButton);
app.component('ion-input', IonInput);
app.component('ion-header', IonHeader);
app.component('ion-checkbox', IonCheckbox);
app.component('ion-textarea', IonTextarea);
app.component('ion-radio-group', IonRadioGroup);
app.component('ion-radio', IonRadio);
app.component('ion-icon', IonIcon);
app.component('ion-select', IonSelect);
app.component('ion-select-option', IonSelectOption);
app.component('ion-accordion', IonAccordion);
app.component('ion-accordion-group', IonAccordionGroup);
app.component('ion-spinner', IonSpinner);
app.component('ion-page', IonPage);

app.config.globalProperties.$baseUrl = "https://form.carsen.wame.sk"

// router.isReady().then(() => {
//   app.mount('#carsen-order-form');
// });

app.mount('#carsen-order-form');