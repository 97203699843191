<template>
    <div class="component-phone-input">
        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/tel.svg`" alt=" " />
        <vue-tel-input
            class="phone-input"
            v-model="phoneNumberCopy"
            :dropdownOptions="dropdownOptions"
            :inputOptions="inputOptions"
            :mode="'international'"
            @validate="phoneValidationData"
        ></vue-tel-input>
    </div>
</template>

<script>
export default {
    name: "PhoneInput",
    props: {
        phoneNumber: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dropdownOptions: {
                disabled: true
            },
            inputOptions: {
                placeholder: '+421 xxx xxx xxx'
            },
        }
    },
    methods: {
        phoneValidationData(event) {
            this.$emit('update:phone-validation', event)
        }
    },
    computed: {
        phoneNumberCopy: {
            get() {
                return this.phoneNumber
            },
            set(value) {
                this.$emit('update:phone-number', value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-phone-input::v-deep {
    position: relative;
    width: 100%;

    .input-icon {
        position: absolute;
        top: 50%;
        transform: translate(11px, -50%);
        z-index: 0;
    }

    .vue-tel-input {
        padding-left: 20px;
        font-size: 15px;
        height: 42px;
        border: 1px solid #ddd;
        border-radius: 4px;
    }
}
</style>