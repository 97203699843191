<template>
    <div class="order-summary">

        <div class="header-wrapper">
            <div class="title">{{ $t('summary') }}</div>
        </div>

        <div class="trip-info-wrapper" v-if="orderData.trips?.length">
            <RoadDesc :road-data="orderData.trips[0]" />
            <RoadDesc v-if="orderData.trips[1]" :road-data="orderData.trips[1]" />
        </div>

        <div class="order-info-summary-container">
            <!-- SECTION TRANSPORT INFORMATION -->
<!--            <div class="order-info-summary-wrapper transport-information-container">-->
<!--                <div class="order-info-title">Informácie o doprave</div>-->
<!--            </div>-->

            <!-- SECTION PRICE SUMMARY - SUM, VAT, FINAL PRICE -->
            <div class="order-info-summary-wrapper price-summary-container">
                <div class="order-info-title">{{ $t('priceSummary') }}</div>
                <div class="price-summary-wrapper">
                    <div class="final-price final-price--price">
                        <div class="label">{{ $t('price') }}</div>
                        <div class="price">{{ orderData.price }} {{ $t('currency') }}</div>
                    </div>
                    <div class="final-price final-price--tax">
                        <div class="label">{{ $t('tax') }}</div>
                        <div class="price">{{ orderData.tax }} {{ $t('currency') }}</div>
                    </div>
                    <div class="final-price final-price--total-price">
                        <div class="label">{{ $t('totalPrice') }}</div>
                        <div class="price">{{ orderData.price_with_tax }} {{ $t('currency') }}</div>
                    </div>
                </div>
            </div>

            <!-- SECTION PAYMENT METHODS -->
            <div class="order-info-summary-wrapper payment-methods-container">
                <div class="order-info-title">{{ $t('payment.method') }}</div>

                <ion-list mode="ios" lines="none" class="payment-method-list">
                    <ion-radio-group class="payment-method-list-group" v-model="summarizationCopy.paymentMethod">
                        <ion-item
                            class="input-wrapper input-online"
                            v-for="method in paymentMethods" :key="method.id"
                        >
                            <ion-label class="label input-online__label">
                                {{ method.title }}
                                <span v-if="method.recommended" class="label-tag">{{ $t('payment.preferred') }}</span>
                            </ion-label>
                            <ion-radio class="input input-online__input" slot="start" :value="method"></ion-radio>
                        </ion-item>
                    </ion-radio-group>

                </ion-list>
                <div v-if="errorMessage" class="error-message-wrapper">{{ errorMessage }}</div>
            </div>
        </div>

        <ion-button
            mode="ios"
            class="btn btn-primary"
            @click="createNewOrderWithSave"
            :class="{ 'btn-loading': loading }"
        >
                <span v-if="!loading" class="btn-content">
                    <span class="btn-content__label">{{ $t('order') }}</span>
                    <ion-icon :icon="chevronForwardOutline" class="btn-content__icon" alt=" "></ion-icon>
                </span>
            <ion-spinner v-else></ion-spinner>
        </ion-button>
    </div>
</template>

<script>
import { arrowBackOutline, chevronForwardOutline } from "ionicons/icons"
import RoadDesc from "./v2/elements/RoadDesc.vue";

export default {
    name: "OrderSummary",
    components: {
        RoadDesc,
    },
    props: {
        orderData: {
            type: Object,
            default() {
                return {}
            }
        },
        summarization: {
            type: Object,
            default() {
                return {}
            }
        },
        paymentMethods: {
            type: Array,
            default() {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            arrowBackOutline, chevronForwardOutline,
        }
    },
    methods: {
        goBack() {
            this.$emit('go-back')
        },
        addDataInput(value) {
            const accordionGroup = document.querySelector('ion-accordion-group');
            if (value) {
                accordionGroup.value = 'registerCompany';
            } else {
                accordionGroup.value = undefined;
            }
        },
        createNewOrderWithSave() {
            this.$emit('next-step')
        }
    },
    computed: {
        summarizationCopy: {
            get() {
                return this.summarization
            },
            set(value) {
                this.$emit('update:summarization', value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.order-summary::v-deep {

    .order-info-summary-container {
        padding: 1px 10px;
        margin: 20px 0;

        .order-info-summary-wrapper {
            margin-bottom: 10px;
        }

        .order-info-title {
            margin-bottom: 10px;
            color: #01ADB5;
            font-size: 15px;
        }

        .transport-information-container {
            //
        }

        .price-summary-container {
            .final-price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 14px 0;

                &--price {
                    font-size: 13px;
                    color: #0C0725;
                }

                &--tax {
                    font-size: 13px;
                    color: #8F8F8F;
                }

                &--total-price {
                    font-size: 17px;
                    color: #0C0725;
                    font-weight: 600;

                    border-bottom: 2px solid #F5F5F5;
                }
            }
        }

        .payment-methods-container {

            .payment-method-list {
                background: white;

                .title {
                    font-size: 18px;
                    font-weight: 600;
                }

                .payment-method-list-group {
                    .label {
                        .label-tag {
                            margin-left: 10px;
                            padding: 2px 10px;
                            font-size: 13px;
                            color: white;
                            border-radius: 7px;
                            background: #01ADB5;
                        }
                    }

                    ion-item {
                        --min-height: 30px;
                    }

                    .input-wrapper {
                        --background: white;

                        ion-radio {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 20px;
                            aspect-ratio: 1;

                            &::part(container) {
                                border: 2px solid #01ADB5;
                                border-radius: 50%;
                                width: 17px;
                                height: 17px;
                            }
                        }

                        .radio-checked {
                            &::part(mark) {
                                border: 0;
                                background: #01ADB5;
                                aspect-ratio: 1/1;
                                border-radius: 50%;
                                width: 9px;
                                height: 9px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 900px) {
        .order-info-summary-container {
            display: flex;
            align-items: flex-start;
            gap: 40px;

            .order-info-summary-wrapper {
                width: calc(100%/2 - 20px);
            }
        }
    }
}
</style>