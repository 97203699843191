<template>
    <div class="validation-error-message">
        {{ $t(tKey) }}
    </div>
</template>

<script>
export default {
    name: "ErrorMessage",
    props: {
        tKey: {
            type: String,
            default: 'enterValue'
        },
    },
}
</script>

<style lang="scss" scoped>
.validation-error-message::v-deep {
    color: #eb445a;
    font-size: 14px;
}
</style>