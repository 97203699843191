<template>
    <div class="order-thank-you">
        <div class="header-wrapper">
            <div class="title">{{ $t('thankYou') }}</div>
        </div>

        <div v-if="somethingWentWrong" class="thank-you-wrapper error-way">
<!--            <div class="thank-you-title">{{ $t('wentWrong') }}</div>-->
            <ion-icon :icon="closeCircle" class="btn-content__icon" alt=" "></ion-icon>
        </div>
        <div v-else class="thank-you-wrapper success-way">
<!--            <div class="thank-you-title">{{ $t('thankYou') }}</div>-->
            <ion-icon :icon="checkmarkCircle" class="btn-content__icon" alt=" "></ion-icon>
        </div>

        <div class="thank-you-content">

            <div class="order-info-wrapper">
                <div v-if="orderData.short_id" class="order-info invoice">
                    <div class="order-info__label">{{ $t('orderNumber') }}:</div>
                    <div class="order-info__number">
                        <img class="icon" :src="`${$baseUrl}/assets/form/icons/cislo_objednavky.svg`" alt=" " />
                        <div class="value">{{ orderData.short_id }}</div>
                    </div>
                </div>

                <div v-if="orderData.payment_method.title" class="order-info method">
                    <div class="order-info__label">{{ $t('payment.method') }}:</div>
                    <div class="order-info__number">
                        <img class="icon" :src="`${$baseUrl}/assets/form/icons/platba_zelena.svg`" alt=" " />
                        <div class="value">{{ orderData.payment_method.title }}</div>
                    </div>
                </div>
            </div>

            <div class="hotline-info">{{ hotlineInfoText }}</div>

            <!--            <ion-button-->
            <!--                mode="ios"-->
            <!--                class="btn btn-primary"-->
            <!--                @click="downloadToCalendar"-->
            <!--            >-->
            <!--                <span class="btn-content">-->
            <!--                    <span class="btn-content__label">{{ $t('downloadCalendar') }}</span>-->
            <!--                    <ion-icon :icon="chevronForwardOutline" class="btn-content__icon" alt=" "></ion-icon>-->
            <!--                </span>-->
            <!--            </ion-button>-->

            <ion-button
                v-if="orderData.paid && orderData.invoice?.invoice_url"
                mode="ios"
                class="btn btn-outline"
                :disabled="loader"
                @click="downloadConfirmation"
            >
                <span class="btn-content">
                    <span class="btn-content__label">{{ $t('downloadConfirmBill') }}</span>
                    <img :src="`${$baseUrl}/assets/form/icons/download.svg`" class="btn-content__image" alt=" " />
                </span>
            </ion-button>

            <div v-if="canRepeatPay" class="payment-not-paid">{{ $t('paymentNotPaid') }}</div>
            <ion-button
                mode="ios"
                v-if="canRepeatPay"
                class="btn btn-danger"
                :disabled="loader"
                @click="paymentRepeat"
            >
<!--                <span class="btn-content">-->
<!--                    <span class="btn-content__label">{{ $t('paymentRepeat') }}</span>-->
<!--                    <ion-icon class="btn-content__icon" :icon="repeatOutline"></ion-icon>-->
<!--                </span>-->
                <span class="btn-content">
                    <span class="btn-content__label">{{ $t('payOnline') }}</span>
                    <ion-icon class="btn-content__icon" :icon="cardOutline"></ion-icon>
                </span>
            </ion-button>

            <ion-button
                mode="ios"
                v-if="canRepeatPay && !orderData.payment_method?.payment_cash && !orderData.payment_method?.payment_as_invoice"
                class="btn btn-primary"
                :disabled="loader"
                @click="changePaymentMethod"
            >
                <span class="btn-content">
                    <span class="btn-content__label">{{ $t('changeToCash') }}</span>
                    <ion-icon class="btn-content__icon" :icon="cashOutline"></ion-icon>
                </span>
            </ion-button>
        </div>
    </div>
</template>

<script>
import { chevronForwardOutline, checkmarkCircle, repeatOutline, cashOutline, closeCircle, cardOutline } from "ionicons/icons";

export default {
    name: "OrderThankYouPage",
    props: {
        /**
         * Info about the order
         */
        orderData: {
            type: Object,
            default() {
                return {}
            }
        },
        loader: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checkmarkCircle, chevronForwardOutline, repeatOutline, cashOutline, closeCircle, cardOutline,
        }
    },
    methods: {
        downloadConfirmation() {
            window.open(this.orderData.invoice?.invoice_url, '_system')
        },
        paymentRepeat() {
            this.$emit('payment-repeat', this.orderData.id)
        },
        changePaymentMethod() {
            this.$emit('change-order-payment')
        }
    },
    computed: {
        canRepeatPay() {
            return this.orderData.id && !this.orderData.paid;
        },
        hotlineInfoText() {
            return this.$t('hotlineInfoText') + ' info@wame.sk'
        },
        somethingWentWrong() {
            return !this.orderData.paid && !this.orderData.payment_method?.payment_cash && !this.orderData.payment_method?.payment_as_invoice
        }
    }
}
</script>

<style lang="scss" scoped>
.order-thank-you::v-deep {

    .thank-you-wrapper {
        position: relative;
        //width: 320px;
        margin: 0 auto;
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;

        .thank-you-title {
            position: absolute;
            top: 30px;
            font-size: 28px;
            font-weight: 600;
        }

        ion-icon {
            color: #00c4cb;
            display: flex;
            z-index: 10;
            //top: 110px;

            width: 100px;
            height: 100px;
            margin: 20px auto;
        }
    }

    .thank-you-content {
        z-index: 10;
        padding: 0 20px;

        .order-info-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-radius: 5px;
            background: #F5F5F5;
            padding: 10px;

            .order-info {
                &__label {
                    color: #01AEB6;
                    font-size: 13px;
                }

                &__number {
                    display: flex;
                    align-items: center;

                    .value {
                        margin-left: 5px;
                        font-size: 17px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .hotline-info {
        font-size: 14px;
        text-align: center;
        margin: 10px 0 20px;
    }

    .payment-not-paid {
        text-align: center;
        font-weight: 600;
        color: #eb445a;
    }
}
</style>