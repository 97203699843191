<template>
    <div class="v-search-select-container">
        <ion-icon class="search-icon" v-if="iconType === 'ion-icon'" :icon="icon"></ion-icon>
        <img class="search-icon" v-if="iconType === 'img'" :src="icon" alt=" " />

        <v-search-select
            class="v-search-select-input"
            :class="[
                selected !== null ? 'remove-redundant-white-space': '',
                iconType ? 'select-icon-space' : ''
            ]"
            v-model="selectedCopy"
            :options="selectOptions"
            :placeholder="placeholder"
            :label="label"
            @search="fetchOptions"
            :filterable="filterable"
        >
            <template #no-options="">
                <div class="no-options-text">{{ $t('startTyping') }}</div>
            </template>
        </v-search-select>
    </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
    name: "SelectInput",
    props: {
        selectOptions: {
            type: Array,
            default() { return [] }
        },
        selected: {
            type: [Object, String],
            default() { return {} }
        },
        iconType: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'title'
        },
        filterable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        fetchOptions: debounce(function (searchTerm, loading) {
            // loading(true)

            this.$emit('fetch-options', {
                searchTerm: searchTerm,
                loadingState: loading
            })
        }, 500),
    },
    computed: {
        selectedCopy: {
            get() {
                return this.selected
            }, set(value) {
                this.$emit('update:selected', value)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.v-search-select-container::v-deep {
    //position: relative;
    //z-index: 2;
    //--vs-search-input-placeholder-color: ;

    // fix bug for white space in select when selected item is longer
    .v-search-select-input {
        &.remove-redundant-white-space {
            .vs__selected-options {
                .vs__search {
                    position: absolute;
                }

                .vs__search:focus {
                    position: relative !important;
                }
            }
        }
    }

    // fix bug with the background item with hover in dropdown menu
    .vs__dropdown-menu {
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        z-index: 5;

        .vs__dropdown-option {
            padding: 7px 20px;
            min-width: 100% !important;
        }
    }

    .v-search-select-input {
        // https://vue-select.org/guide/css.html#dark-mode-example
        --vs-border-color: #ddd;
        --vs-border-radius: 4px;
        --vs-font-size: 15px;

        font-size: 14px; // font size in dropdown
        z-index: 0;

        .vs__search, .vs__selected {
            padding-left: 30px;
        }

        .vs__open-indicator {
            opacity: 0;
        }

        .vs__dropdown-toggle {
            min-height: 42px;
            background: white;
        }

        .vs__dropdown-menu {
            padding: 5px 10px;
        }

        .vs2__listbox {
            //
        }

        .search-icon {
            //z-index: 3;
        }

        .vs__actions {
            position: relative;

            &:after {
                position: relative;
                content: "";
                //top: 50%;
                //transform: translate(0, -50%);
                left: -5px;
                display: flex;
                border-top: 5px solid #01A6AE;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                transition: transform 0.2s ease-in-out;
            }
        }

        &.vs--open {
            .vs__actions:after {
                transform: rotate(180deg);
            }
        }
    }
}
</style>