export default {
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková cena"])},
  "priceSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumár ceny"])},
  "withTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s DPH"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednať"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumarizácia"])},
  "deliveryData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodacie údaje"])},
  "cancelSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť výber"])},
  "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďakujeme za Vašu objednávku"])},
  "wentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba nebola uradená"])},
  "downloadCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť do kalendára"])},
  "downloadConfirmBill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť potvrdenie"])},
  "paymentRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zopakovať platbu"])},
  "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť online"])},
  "changeToCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť vo vozidle"])},
  "wantWayBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prajete si objednať spätnú cestu?"])},
  "countrySelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte krajinu"])},
  "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne výsledky"])},
  "placeholderCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať mesto"])},
  "startTyping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začnite písať"])},
  "createNewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť novú objednávku"])},
  "billingInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturačné údaje"])},
  "paymentNotPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba ešte nebola uhradená"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum vyzdvihnutia"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas vyzdvihnutia"])},
  "dateAndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum a čas vyzdvihnutia"])},
  "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo objednávky"])},
  "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo letu"])},
  "childSeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detská sedačka"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto vyzdvihnutia"])},
  "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cieľ cesty"])},
  "startLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto nástupu"])},
  "endLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto výstupu"])},
  "destinationTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cieľ cesty"])},
  "baggage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batožina"])},
  "location": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
    "passengers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet osôb"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "sendConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslať potvrdenie"])},
    "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj si prajete vymazať miesto nástupu?"])},
    "addPickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať daľšie miesto vyzdvihnutia"])},
    "addOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať daľšie miesto výstupu"])}
  },
  "wayThere": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta tam"])}
  },
  "wayBack": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spätná cesta"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta späť"])}
  },
  "childSeats": {
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vek dietaťa"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať detskú sedačku"])},
    "chooseAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte vek vášho dieťaťa"])},
    "deleteConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naozaj si prajete vymazať detskú sedačku?"])}
  },
  "suitcase": {
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet kufrov"])},
    "chooseSmallCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte počet malých kufrov"])},
    "chooseBigCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte počet veľkých kufrov"])},
    "small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malé kufre"])},
    "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľké kufre"])}
  },
  "payment": {
    "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spôsob platby"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vo vozidle (hotovosť/karta)"])},
    "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online platba"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na faktúru"])},
    "preferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferované"])}
  },
  "enterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte hodnotu"])},
  "address": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])}
  },
  "billing": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email objednávajúceho"])},
    "billToCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcem faktúrovať na firmu"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov spoločnosti"])},
    "businessId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IČO"])},
    "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIČ"])},
    "vatId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IČ DPH"])},
    "costCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Centrum"])},
    "selectCostCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte Cost Centrum"])}
  },
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalej"])},
  "$vForm": {
    "name": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte meno cestujúceho"])}
    },
    "lastname": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte priezvisko cestujúceho"])}
    },
    "boardingPlace": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte miesto nástupu"])}
    },
    "tripDestination": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte miesto výstupu"])}
    },
    "date": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte dátum vyzdvihnutia"])},
      "minSelectedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvolený čas nemôže byť v minulosti"])}
    },
    "time": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte čas vyzdvihnutia"])}
    },
    "tel": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte telefónne číslo"])},
      "phoneIsAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte telefónne číslo v tvare +421 xxx xxx xxx"])}
    },
    "email": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte email cestujúceho"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte email v správnom tvare"])}
    },
    "childSeatsCopy": {
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte vek dieťaťa"])}
    },
    "firstName": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte prosím meno objednávateľa"])}
    },
    "lastName": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte priezvisko objednávateľa"])}
    },
    "street": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte ulicu"])}
    },
    "zipCode": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte PSČ"])},
      "minLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte PSČ v správnom tvare"])},
      "maxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte PSČ v správnom tvare"])}
    },
    "city": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte mesto"])}
    },
    "countryId": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte krajinu"])}
    },
    "phone": {
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte telefónne číslo"])},
      "phoneIsAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosím zadajte telefónne číslo v tvare +421 xxx xxx xxx"])}
    }
  },
  "hotlineInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade potreby viete svoju objednávku upraviť, alebo kontaktujte hotline"])}
}