<template>
    <div class="component-order-way-there">
        <div class="header-wrapper">
            <div class="title">{{ $t('wayThere.label') }}</div>
        </div>

        <div class="in-cols-2">
            <div class="pick-up-locations-container">
                <PickUpLocations
                    type="there"
                    v-model:locations="orderDataCopy.locations"
                    @add-next-stop="addNextStop"
                    @delete-location="deleteLocation"
                    ref="ref_pick_up_location_way_there"
                />
            </div>

            <div class="global-form-wrapper">

                <div class="input-wrapper input-background-gray">
                    <div class="label">{{ $t('destination') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/nastup.svg`" alt=" " />
                        <SearchInput
                            label="place"
                            v-model:selected="orderDataCopy.tripDestination"
                            :select-options="placeOptions"
                            @fetch-options="fetchOptionsPlace"
                        />
                    </div>
                    <ErrorMessage v-if="validationError('tripDestination')" :tKey="validationMessage('tripDestination')" />
                </div>

                <div class="input-wrapper">
                    <div class="label">{{ $t('flightNumber') }}</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/let.svg`" alt=" " />
                        <input
                            class="input input--with-icon"
                            v-model="orderDataCopy.flightNumber"
                            name="flight number"
                        />
                    </div>
                </div>

                <div class="select-suitcases-container">
                    <div class="label">{{ $t('baggage') }}</div>
                    <div class="select-suitcases-wrapper">
                        <SuitcaseSelect
                            v-model:value="orderDataCopy.smallSuitcases"
                            :count-of-options="6"
                            selectType="small"
                        />

                        <SuitcaseSelect
                            v-model:value="orderDataCopy.bigSuitcases"
                            :count-of-options="4"
                            selectType="big"
                        />
                    </div>
                </div>

<!--                <ion-item class="input-wrapper input-note">-->
<!--                    <ion-label position="stacked" class="label input-note__label">{{ $t('note') }}</ion-label>-->
<!--                    <ion-textarea-->
<!--                        v-model="orderDataCopy.note"-->
<!--                        class="textarea input-note__input"-->
<!--                        :autoGrow="true"-->
<!--                        :autocapitalize="'on'"-->
<!--                        :rows="1"-->
<!--                    ></ion-textarea>-->
<!--                </ion-item>-->

                <div class="input-wrapper">
                    <div class="label">{{ $t('note') }}</div>
                    <div class="input-container">
                        <textarea
                            class="textarea"
                            v-model="orderDataCopy.note"
                            name="note"
                        />
                    </div>
                </div>

                <ChildSeats
                    v-model:child-seats="orderDataCopy.childSeats"
                    @add-child-seat="addChildSeat"
                    @delete-child-seat="deleteChildSeat"
                    ref="ref_child_seats"
                />

            </div>
        </div>


<!--        <div class="drop-location-wrap container">-->
<!--            <ion-list lines="none" class="general-form-container">-->

<!--                <div class="input-wrapper input-destination">-->
<!--                    <ion-label position="stacked" class="label input-destination__label">{{ $t('destination') }} *</ion-label>-->
<!--                    <SearchInput class="input-destination__input" v-model:selected="orderDataCopy.tripDestination" icon-type="ciel" />-->
<!--                    <ErrorMessage v-if="validationError('tripDestination')" :tKey="validationMessage('tripDestination')" />-->
<!--                </div>-->

<!--                <ion-item class="input-wrapper input-fight">-->
<!--                    <ion-label position="stacked" class="label input-fight__label">{{ $t('flightNumber') }}</ion-label>-->

<!--                    <div class="input-container">-->
<!--                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/let.svg`" alt=" " />-->
<!--                        <ion-input type="text" :maxlength="255" v-model="orderDataCopy.flightNumber" class="input input-fight__input"></ion-input>-->
<!--                    </div>-->
<!--                </ion-item>-->

<!--                <span class="break-line"></span>-->

<!--                <div class="select-suitcases-container">-->
<!--                    <div class="label">{{ $t('baggage') }}</div>-->

<!--                    <div class="select-suitcases-wrapper">-->

<!--                        <SuitcaseSelect-->
<!--                            v-model:value="orderDataCopy.smallSuitcases"-->
<!--                            :count-of-options="6"-->
<!--                            selectType="small"-->
<!--                        />-->

<!--                        <SuitcaseSelect-->
<!--                            v-model:value="orderDataCopy.bigSuitcases"-->
<!--                            :count-of-options="4"-->
<!--                            selectType="big"-->
<!--                        />-->
<!--                    </div>-->
<!--                </div>-->

<!--                <span class="break-line"></span>-->

<!--                <ion-item class="input-wrapper input-note">-->
<!--                    <ion-label position="stacked" class="label input-note__label">{{ $t('note') }}</ion-label>-->
<!--                    <ion-textarea-->
<!--                        v-model="orderDataCopy.note"-->
<!--                        class="textarea input-note__input"-->
<!--                        :autoGrow="true"-->
<!--                        :autocapitalize="'on'"-->
<!--                        :rows="1"-->
<!--                    ></ion-textarea>-->
<!--                </ion-item>-->

<!--                <span class="break-line"></span>-->

<!--                <ChildSeats-->
<!--                    v-model:child-seats="orderDataCopy.childSeats"-->
<!--                    @add-child-seat="addChildSeat"-->
<!--                    @delete-child-seat="deleteChildSeat"-->
<!--                    ref="ref_child_seats"-->
<!--                />-->
<!--            </ion-list>-->
<!--        </div>-->

        <div class="container">
            <ion-button
                mode="ios"
                class="btn btn-primary"
                @click="chooseReturn"
            >
                <span class="btn-content">
                    <span class="btn-content__label">{{ $t('nextStep') }}</span>
                    <ion-icon :icon="chevronForwardOutline" class="btn-content__icon" alt=" "></ion-icon>
                </span>
            </ion-button>
        </div>

    </div>
</template>

<script>
import { chevronForwardOutline } from "ionicons/icons"
import SuitcaseSelect from "@/components/elements/SuitcaseSelect";
import ChildSeats from "@/components/elements/ChildSeats";
import ErrorMessage from "./common/ErrorMessage";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import SearchInput from "./v2/elements/SelectInput.vue";
import PickUpLocations from "./elements/PickUpLocations";

export default {
    name: "OrderWayThere",
    components: {
        PickUpLocations,
        SearchInput,
        ErrorMessage,
        ChildSeats,
        SuitcaseSelect,
    },
    props: {
        /**
         * Other data from the order
         */
        orderData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup () {
        return {
            v$: useVuelidate(),
        }
    },
    data() {
        return {
            chevronForwardOutline,

            placeOptions: []
        }
    },
    watch: {
        'orderDataCopy.tripDestination': {
            handler() {
                if (this.orderDataCopy.tripDestination) this.callPartValidation('tripDestination')
            }
        },
    },
    methods: {
        fetchOptionsPlace(searchData) {
            if (!searchData.searchTerm) return false

            let apiURL = this.$config.API_URL_V1 + 'googlemaps/autocomplete'
            apiURL += '?searchParam=' + searchData.searchTerm

            this.axios.get(apiURL, this.$config.HEADERS).then(response => {
                // console.log('getCountries', response)
                this.placeOptions = response.data
            }, (error) => {
                console.warn(error.response.data)
            }).finally(() => {
                //
            })
        },
        addNextStop() {
            this.$emit('add-next-stop')
        },
        deleteLocation(locationId) {
            this.$emit('delete-location', locationId)
        },
        deleteChildSeat(index) {
            this.$emit('delete-child-seat', index)
        },
        addChildSeat() {
            this.$emit('add-child-seat')
        },
        chooseReturn() {
            this.$refs.ref_pick_up_location_way_there.validateFormData()
            this.$refs.ref_child_seats.validateFormData()

            this.$nextTick(() => {
                let validateLocationStates = []

                // validate locations
                validateLocationStates = [...this.$refs.ref_pick_up_location_way_there.$refs.ref_location.map(ref => ref.v$.$invalid)]

                // validate child seat - age must be > 0
                validateLocationStates = [...validateLocationStates, ...[this.$refs.ref_child_seats.v$.$invalid]]

                this.v$.$touch()
                validateLocationStates = [...validateLocationStates, ...[this.v$.$invalid]]

                const errorExist = validateLocationStates.some(item => item)
                if (!errorExist) {
                    this.$emit('next-step')
                } else {
                    this.scrollToError()
                }
            })
        },
        callPartValidation(key) {
            this.v$.orderDataCopy[key].$touch()
        },
        scrollToError() {
            const errorContainer = document.querySelector('.validation-error-message')?.parentNode
            if (errorContainer) this.$parent.$parent.$parent.$parent.scrollToContentPosition()
        },
    },
    computed: {
        orderDataCopy: {
            get() {
                return this.orderData
            },
            set(value) {
                this.$emit('update:order-data', value)
            }
        },
        validationError() {
            return key => !!(this.v$.orderDataCopy[key].$errors.length)
        },
        validationMessage() {
            return key => {
                const errorKey = this.v$.orderDataCopy[key].$errors[0].$validator
                return '$vForm.' + key + '.' + errorKey
            }
        },
    },
    validations () {
        return {
            orderDataCopy: {
                tripDestination: {
                    required
                }
            }
        }
    }
}
</script>

<style lang="scss">
input[type="date" i]::-webkit-calendar-picker-indicator {
    filter: none !important;
    background-image: url('../assets/icons/calendarOutline.svg');
}

input[type="time" i]::-webkit-calendar-picker-indicator {
    filter: none !important;
    background-image: url('../assets/icons/timeOutline.svg');
}

.component-order-way-there::v-deep {
    //
}
</style>