<template>
    <div class="component-pick-up-locations">

        <div class="locations-wrapper">
            <TheLocation
                :type="type"
                v-for="(locationItem, index) in locationsCopy" :key="index"
                :location-index="index"
                :location="locationItem"
                @delete-location="deleteLocation"
                ref="ref_location"
            />
        </div>

        <div class="add-button" @click="addNextStop">

            <span class="btn-content">
                <span class="btn-content__label">{{ addItemLabel }}</span>
                <img class="btn-content__image" :src="`${$baseUrl}/assets/form/icons/addIcon.svg`" alt=" " />
            </span>
        </div>
    </div>
</template>

<script>
import TheLocation from "../v2/elements/TheLocation.vue";

export default {
    name: "PickUpLocations",
    components: {
        TheLocation,
    },
    props: {
        type: {
            type: String,
            default: '' // there / back
        },
        locations: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
        }
    },
    methods: {
        addNextStop() {
            this.$emit('add-next-stop')
        },
        deleteLocation(index) {
            this.$emit('delete-location', index)
        },
        validateFormData() {
            this.$refs.ref_location.forEach(ref => ref.validateFormData());
        }
    },
    computed: {
        locationsCopy: {
            get() {
                return this.locations
            },
            set(value) {
                this.$emit('update:locations', value)
            }
        },
        addItemLabel() {
            return this.type === 'there' ? this.$t('location.addPickup') : this.$t('location.addOut')
        }
    }
}
</script>

<style lang="scss" scoped>
.component-pick-up-locations::v-deep {
    .add-button {
        display: flex;
        flex-direction: column;
    }
}
</style>