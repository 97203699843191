<template>
    <div class="number-stepper">
        <div class="stepper-item stepper-icon">
            <img class="icon" :src="`${$baseUrl}/assets/form/icons/osoby.svg`" alt=" " />
        </div>
        <div
            class="stepper-item count-decrease"
            @click="decreaseNumber"
        >
            <ion-icon class="count-button" :class="{ 'disable-element': count === 1 }" :src="removeOutline" alt=" "></ion-icon>
        </div>
        <div class="stepper-item count-number">{{ count }}</div>
        <div
            class="stepper-item count-increase"
            @click="increaseNumber"
        >
            <ion-icon class="count-button" :src="addOutline" alt=" "></ion-icon>
        </div>
    </div>
</template>

<script>
import { addOutline, removeOutline } from "ionicons/icons"

export default {
    name: "NumberStepper",
    props: {
        /**
         * The smallest value that the user can choose
         */
        minimumValue: {
            type: Number,
            default: 1
        },
        count: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            addOutline, removeOutline,
        }
    },
    methods: {
        decreaseNumber() {
            if (this.minimumValue < this.count) this.$emit('decrease-number')
        },
        increaseNumber() {
            this.$emit('increase-number')
        }
    },
}
</script>

<style lang="scss" scoped>
.number-stepper::v-deep {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--ion-color-border-gray);
    border-radius: 4px;

    .stepper-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;

        padding: 8px 0;
    }

    .stepper-icon {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
        cursor: pointer;
    }

    @media (max-width: 800px) {
        max-width: calc(100%/2 - 5px);
    }
}
</style>