<template>
    <div class="component-suitcase-select">
        <div class="select-header">
            <img class="input-icon" :src="sheetOptions.icon" alt=" " />
            <div class="label">{{ sheetOptions.label }}</div>
        </div>
        <select
            ref="suitcaseSelect"
            class="select"
            v-model="valueCopy"
        >
            <option
                v-for="item in options" :key="item.value"
                :value="item.value"
            >
                {{ item.value }}
            </option>
        </select>

<!--        <ion-item mode="ios" class="input-wrapper select-suitcases">-->
<!--			<div class="custom-select-suitcase-wrap">-->
<!--                <img class="input-icon" :src="sheetOptions.icon" alt=" " />-->
<!--				<ion-label class="label select-suitcases__label">{{ sheetOptions.label }}</ion-label>-->
<!--                <select-->
<!--					ref="suitcaseSelect"-->
<!--					class="select-suitcases__select"-->
<!--					v-model="valueCopy"-->
<!--				>-->
<!--					<option-->
<!--						v-for="item in options" :key="item.value"-->
<!--						:value="item.value"-->
<!--					>{{ item.value }}</option>-->
<!--				</select>-->
<!--			</div>-->

<!--            <ion-select-->
<!--                class="select-suitcases__select"-->
<!--                v-model="valueCopy"-->
<!--                interface="alert"-->
<!--                :placeholder="$t('suitcase.count')" :cancelText="$t('cancelSelection')"-->
<!--                :interface-options="sheetOptions"-->
<!--            >-->
<!--                <ion-select-option-->
<!--                    v-for="item in options" :key="item.value"-->
<!--                    :value="item.value"-->
<!--                >-->
<!--                    {{ item.value }}-->
<!--                </ion-select-option>-->
<!--            </ion-select>-->
<!--        </ion-item>-->
    </div>
</template>

<script>
export default {
    name: "SuitcaseSelect",
    props: {
        value: {
            type: Number,
            default: 0
        },
        countOfOptions: {
            type: Number,
            default: 0
        },
        selectType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            options: [],

            sheetOptionsSmall: {
                header: this.$t('suitcase.chooseSmallCount'),
                label: this.$t('suitcase.small'),
                icon: `${this.$baseUrl}/assets/form/icons/tel.svg`
            },
            sheetOptionsBig: {
                header: this.$t('suitcase.chooseBigCount'),
                label: this.$t('suitcase.big'),
                icon: `${this.$baseUrl}/assets/form/icons/datum.svg`
            },
        }
    },
    mounted() {
        this.createChildSeatsOptions()
    },
    methods: {
        async createChildSeatsOptions() {
            this.options = await this.createOptions(this.countOfOptions)
        },
    },
    computed: {
        valueCopy: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('update:value', val)
            }
        },
        sheetOptions() {
            if (this.selectType === 'small') {
                return this.sheetOptionsSmall
            } else {
                return this.sheetOptionsBig
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-suitcase-select::v-deep {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid var(--ion-color-border-gray);
    border-radius: 4px;
    height: 42px;
    padding: 0 10px;

    .select-header {
        display: flex;
        align-items: center;

        .label {
            margin-left: 10px;
        }
    }

    select {
        //-moz-appearance:none; /* Firefox */
        //-webkit-appearance:none; /* Safari and Chrome */
        //appearance:none;

        //&::after {
        //    position: absolute;
        //    content: "";
        //    display: flex;
        //    border-top: 5px solid #01A6AE;
        //    border-right: 5px solid transparent;
        //    border-left: 5px solid transparent;
        //    transition: transform 0.2s ease-in-out;
        //}
    }
}
</style>