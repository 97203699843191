<template>
    <div class="order-billing-information-v2">
        <div class="header-wrapper">
            <div class="title">{{ $t('billingInformation') }}</div>
        </div>

        <div class="global-form-wrapper">

            <div class="billing-customer-info-wrapper">

                <div class="input-wrapper" style="position:relative; z-index: 3;">
                    <div class="label">{{ $t('location.name') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/meno.svg`" alt=" " />
                        <SearchInput
                            v-if="adminLogged"
                            v-model:selected="customerBillingInfoCopy.firstName"
                            :select-options="customersOptions"
                            @fetch-options="fetchOptionsCustomers"
                        />
                        <input
                            v-else
                            class="input input--with-icon"
                            v-model="customerBillingInfoCopy.firstName"
                            name="name"
                        />
                    </div>
                    <ErrorMessage
                        v-if="validationError('customerBillingInfoCopy', 'firstName')"
                        :tKey="validationMessage('customerBillingInfoCopy', 'firstName')"
                    />
                </div>

                <div class="input-wrapper" style="position:relative; z-index: 2;">
                    <div class="label">{{ $t('billing.email') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/mail.svg`" alt=" " />
                        <SearchInput
                            v-if="adminLogged"
                            v-model:selected="companyBillingInfoCopy.email"
                            :select-options="emailOptions"
                            @fetch-options="fetchOptionsEmail"
                        />
                        <input
                            v-else
                            class="input input--with-icon"
                            v-model="companyBillingInfoCopy.email"
                            name="email"
                        />
                    </div>
                    <ErrorMessage
                        v-if="validationError('companyBillingInfoCopy', 'email')"
                        :tKey="validationMessage('companyBillingInfoCopy', 'email')"
                    />
                </div>

                <div class="input-wrapper" style="position:relative; z-index: 1;">
                    <div class="label">{{ $t('address.phone') }} *</div>
                    <div class="input-container">
                        <PhoneInput
                            class="input--tel"
                            v-model:phone-number="customerBillingInfoCopy.phone"
                            v-model:phone-validation="phoneValidation"
                        />
                    </div>
                    <ErrorMessage
                        v-if="validationError('customerBillingInfoCopy', 'phone')"
                        :tKey="validationMessage('customerBillingInfoCopy', 'phone')"
                    />
                </div>
            </div>

            <div class="input-wrapper checkbox-wrapper" @click="addBillingInformation">
                <input
                    class="checkbox"
                    :checked="companyBillingInfoCopy.enterBillingInformation"
                    type="checkbox"
                />
                <div class="label">{{ $t('billing.billToCompany') }}</div>
            </div>

            <ion-accordion-group id="add-billing-information-group" mode="ios">
                <ion-accordion value="billingInformation">
                    <div slot="content">

                        <div class="section section--1">

                            <div class="input-wrapper">
                                <div class="label">{{ $t('billing.company') }}</div>
                                <div class="input-container">
                                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/spolocnost.svg`" alt=" " />
                                    <SearchInput
                                        v-if="adminLogged"
                                        v-model:selected="companyBillingInfoCopy.companyName"
                                        :select-options="companiesOptions"
                                        @fetch-options="fetchOptionsCompanies"
                                    />
                                    <input
                                        v-else
                                        v-model="companyBillingInfoCopy.companyName"
                                        class="input input--with-icon"
                                    />
                                </div>
                            </div>

                            <div class="input-wrapper" v-if="adminLogged">
                                <div class="label">{{ $t('billing.costCentre') }}</div>
                                <div class="input-container">
                                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/spolocnost.svg`" alt=" " />
                                    <SearchInput
                                        v-if="adminLogged"
                                        v-model:selected="costCentre"
                                        :select-options="costCentreOptions"
                                        :filterable="true"
                                    />
                                    <input
                                        v-else
                                        v-model="costCentre"
                                        class="input input--with-icon"
                                    />
                                </div>
                                <div class="error-message"></div>
                            </div>
                        </div>

                        <div class="section section--2">
                            <div class="input-wrapper">
                                <div class="label">{{ $t('billing.businessId') }}</div>
                                <div class="input-container">
                                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/ico.svg`" alt=" " />
                                    <input
                                        class="input input--with-icon"
                                        v-model="companyBillingInfoCopy.businessId"
                                    />
                                </div>
                            </div>

                            <div class="input-wrapper">
                                <div class="label">{{ $t('billing.taxId') }}</div>
                                <div class="input-container">
                                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/dic.svg`" alt=" " />
                                    <input
                                        class="input input--with-icon"
                                        v-model="companyBillingInfoCopy.taxId"
                                    />
                                </div>
                                <div class="error-message"></div>
                            </div>

                            <div class="input-wrapper">
                                <div class="label">{{ $t('billing.vatId') }}</div>
                                <div class="input-container">
                                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/ic_dph.svg`" alt=" " />
                                    <input
                                        class="input input--with-icon"
                                        v-model="companyBillingInfoCopy.vatId"
                                    />
                                </div>
                                <div class="error-message"></div>
                            </div>
                        </div>
                    </div>
                </ion-accordion>
            </ion-accordion-group>

            <div class="break-line"></div>

            <div class="address-wrapper">
                <div class="input-wrapper">
                    <div class="label">{{ $t('address.street') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/ulica.svg`" alt=" " />
                        <input
                            class="input input--with-icon"
                            v-model="customerBillingInfoCopy.street"
                            name="street"
                        />
                    </div>
                    <ErrorMessage
                        v-if="validationError('customerBillingInfoCopy', 'street')"
                        :tKey="validationMessage('customerBillingInfoCopy', 'street')"
                    />
                </div>

                <div class="input-wrapper">
                    <div class="label">{{ $t('address.zipCode') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/psc.svg`" alt=" " />
                        <input
                            class="input input--with-icon"
                            v-model="customerBillingInfoCopy.zipCode"
                            name="zip"
                        />
                    </div>
                    <ErrorMessage
                        v-if="validationError('customerBillingInfoCopy', 'zipCode')"
                        :tKey="validationMessage('customerBillingInfoCopy', 'zipCode')"
                    />
                </div>

                <div class="input-wrapper">
                    <div class="label">{{ $t('address.city') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/mesto.svg`" alt=" " />
                        <input
                            class="input input--with-icon"
                            v-model="customerBillingInfoCopy.city"
                            name="city"
                        />
                    </div>
                    <ErrorMessage
                        v-if="validationError('customerBillingInfoCopy', 'city')"
                        :tKey="validationMessage('customerBillingInfoCopy', 'city')"
                    />
                </div>

                <div class="input-wrapper">
                    <div class="label">{{ $t('address.country') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/krajina.svg`" alt=" " />
                        <SearchInput
                            v-model:selected="customerBillingInfoCopy.countryId"
                            :select-options="countriesOptions"
                            :filterable="true"
                        />
<!--                            @fetch-options="fetchOptionsEmail"-->
                    </div>
                    <ErrorMessage
                        v-if="validationError('customerBillingInfoCopy', 'countryId')"
                        :tKey="validationMessage('customerBillingInfoCopy', 'countryId')"
                    />
                </div>
            </div>

        </div>

        <!-- SECTION OLD -->

<!--        <ion-list lines="none" class="general-form-container">-->

<!--            <div class="billing-info-customer-company">-->
<!--                <ion-item class="input-wrapper input-first-name">-->
<!--                    <ion-label position="stacked" class="label input-first-name__label">{{ $t('location.name') }} *</ion-label>-->
<!--                    <SearchInputCustomer />-->
<!--                    <ErrorMessage v-if="validationError('firstName')" :tKey="validationMessage('firstName')" />-->
<!--                </ion-item>-->

<!--                <ion-item class="input-wrapper input-company-name">-->
<!--                    <ion-label position="stacked" class="label input-first-name__label">{{ $t('location.name') }} *</ion-label>-->
<!--                    <SearchInputCompany />-->
<!--                </ion-item>-->

<!--                <ion-item class="input-wrapper input-tel" lines="none">-->
<!--                    <ion-label class="label input-tel__label" position="stacked">{{ $t('address.phone') }} *</ion-label>-->
<!--                    <PhoneInput class="input-tel__input" v-model:phone-number="customerBillingInfoCopy.phone" v-model:phone-validation="phoneValidation" />-->
<!--                    <ErrorMessage v-if="validationError('phone')" :tKey="validationMessage('phone')" />-->
<!--                </ion-item>-->
<!--            </div>-->

<!--            <ion-row class="date-time-wrapper">-->
<!--                <ion-col size="12" sizeMd="6">-->
<!--                    <ion-item class="input-wrapper input-first-name">-->
<!--                        <ion-label position="stacked" class="label input-first-name__label">{{ $t('location.name') }} *</ion-label>-->
<!--                        <div class="input-container">-->
<!--                            <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/meno.svg`" alt=" " />-->
<!--                            <ion-input-->
<!--                                name="name" autocomplete="name" type="text" :autocapitalize="'on'" :maxlength="255"-->
<!--                                v-model="customerBillingInfoCopy.firstName"-->
<!--                                class="input input-first-name__input"-->
<!--                                @ionChange="callPartValidation('firstName')"-->
<!--                            ></ion-input>-->
<!--                        </div>-->
<!--                        <ErrorMessage v-if="validationError('firstName')" :tKey="validationMessage('firstName')" />-->
<!--                    </ion-item>-->
<!--                </ion-col>-->
<!--                <ion-col size="12" sizeMd="6">-->
<!--                    <ion-item class="input-wrapper input-tel" lines="none">-->
<!--                        <ion-label class="label input-tel__label" position="stacked">{{ $t('address.phone') }} *</ion-label>-->
<!--                        <PhoneInput class="input-tel__input" v-model:phone-number="customerBillingInfoCopy.phone" v-model:phone-validation="phoneValidation" />-->
<!--                    </ion-item>-->
<!--                    <ErrorMessage v-if="validationError('phone')" :tKey="validationMessage('phone')" />-->
<!--                </ion-col>-->
<!--            </ion-row>-->

<!--            <BillingInformation-->
<!--                v-model:order-data="companyBillingInfoCopy"-->
<!--                @prefill-data="prefillData"-->
<!--                ref="ref_billing_information"-->
<!--            />-->

<!--&lt;!&ndash;            <ion-item class="input-wrapper input-last-name">&ndash;&gt;-->
<!--&lt;!&ndash;                <ion-label position="stacked" class="label input-last-name__label">{{ $t('address.lastName') }}</ion-label>&ndash;&gt;-->
<!--&lt;!&ndash;                <ion-input&ndash;&gt;-->
<!--&lt;!&ndash;                    name="lastname" autocomplete="family-name" type="text" :autocapitalize="'on'" :maxlength="255"&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model="customerBillingInfoCopy.lastName"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="input input-last-name__input"&ndash;&gt;-->
<!--&lt;!&ndash;                    @ionChange="callPartValidation('lastName')"&ndash;&gt;-->
<!--&lt;!&ndash;                ></ion-input>&ndash;&gt;-->
<!--&lt;!&ndash;                <ErrorMessage v-if="validationError('lastName')" :tKey="validationMessage('lastName')" />&ndash;&gt;-->
<!--&lt;!&ndash;            </ion-item>&ndash;&gt;-->

<!--            <ion-item class="input-wrapper input-street">-->
<!--                <ion-label position="stacked" class="label input-street__label">{{ $t('address.street') }} *</ion-label>-->
<!--                <div class="input-container">-->
<!--                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/ulica.svg`" alt=" " />-->
<!--                    <ion-input-->
<!--                        name="street-address" autocomplete="street-address" type="text" :autocapitalize="'on'" :maxlength="255"-->
<!--                        v-model="customerBillingInfoCopy.street"-->
<!--                        class="input input-street__input"-->
<!--                        @ionChange="callPartValidation('street')"-->
<!--                    ></ion-input>-->
<!--                </div>-->
<!--                <ErrorMessage v-if="validationError('street')" :tKey="validationMessage('street')" />-->
<!--            </ion-item>-->

<!--            <ion-item class="input-wrapper input-zip-code">-->
<!--                <ion-label position="stacked" class="label input-zip-code__label">{{ $t('address.zipCode') }} *</ion-label>-->
<!--                <div class="input-container">-->
<!--                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/psc.svg`" alt=" " />-->
<!--                    <ion-input-->
<!--                        name="postal-code" autocomplete="postal-code" pattern="[0-9]*" :maxlength="5"-->
<!--                        v-model="customerBillingInfoCopy.zipCode"-->
<!--                        class="input input-zip-code__input"-->
<!--                        @ionChange="callPartValidation('zipCode')"-->
<!--                    ></ion-input>-->
<!--                </div>-->
<!--                <ErrorMessage v-if="validationError('zipCode')" :tKey="validationMessage('zipCode')" />-->
<!--            </ion-item>-->

<!--            <ion-item class="input-wrapper input-city">-->
<!--                <ion-label position="stacked" class="label input-city__label">{{ $t('address.city') }} *</ion-label>-->
<!--                <div class="input-container">-->
<!--                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/mesto.svg`" alt=" " />-->
<!--                    <ion-input-->
<!--                        name="postal-code" autocomplete="postal-code" type="text" :autocapitalize="'on'" :maxlength="255"-->
<!--                        v-model="customerBillingInfoCopy.city"-->
<!--                        class="input input-city__input"-->
<!--                        @ionChange="callPartValidation('city')"-->
<!--                    ></ion-input>-->
<!--                </div>-->
<!--                <ErrorMessage v-if="validationError('city')" :tKey="validationMessage('city')" />-->
<!--            </ion-item>-->

<!--            <div class="input-wrapper input-country">-->
<!--                <ion-label position="stacked" class="label input-country__label">{{ $t('address.country') }} *</ion-label>-->
<!--                <SearchDropdownInput-->
<!--                    class="input-country__input"-->
<!--                    v-model:selected="customerBillingInfoCopy.countryId"-->
<!--                />-->
<!--                <ErrorMessage v-if="validationError('countryId')" :tKey="validationMessage('countryId')" />-->
<!--            </div>-->
<!--        </ion-list>-->

<!--        <div v-if="errorMessage" class="error-message-wrapper">{{ errorMessage }}</div>-->

        <div class="container">
            <ion-button
                mode="ios"
                class="btn btn-primary"
                @click="checkSummarization"
                :class="{ 'btn-loading': loading }"
            >
                <span v-if="!loading" class="btn-content">
                    <span class="btn-content__label">{{ $t('summary') }}</span>
                    <ion-icon :icon="chevronForwardOutline" class="btn-content__icon" alt=" "></ion-icon>
                </span>
                <ion-spinner v-else></ion-spinner>
            </ion-button>
        </div>

    </div>
</template>

<script>
import { IonAccordionGroup, IonAccordion } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons"
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import SearchInput from "./v2/elements/SelectInput.vue";

// import BillingInformation from "./elements/BillingInformation";
import PhoneInput from "./elements/PhoneInput";
// import SearchDropdownInput from "./elements/SearchDropdownInput";
// import { IonRow, IonCol } from "@ionic/vue"
// import SearchInputCustomer from "./elements/SearchInputCustomer.vue";
// import SearchInputCompany from "./elements/SearchInputCompany.vue";
import ErrorMessage from "./common/ErrorMessage";

// eslint-disable-next-line
const phoneIsAccepted = (value, {}, vm) => {
    return !!(
        value.length
        && vm.phoneValidation
        && vm.phoneValidation.valid
    );
}

export default {
    name: "OrderBillingInformation",
    components: {
        SearchInput,
        // SearchInputCompany,
        // SearchInputCustomer,
        // SearchDropdownInput,
        PhoneInput,
        // BillingInformation,
        ErrorMessage,
        // IonRow,
        // IonCol
        IonAccordionGroup,
        IonAccordion
    },
    props: {
        customerBillingInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        companyBillingInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        },
        adminToken: {
            type: String,
            default: null
        }
    },
    setup () {
        return {
            v$: useVuelidate(),
        }
    },
    data() {
        return {
            chevronForwardOutline,

            testSelect: 'sunka',
            testOptions: ['test1', 'test2'],

            phoneValidation: null,

            customersOptions: [],
            emailOptions: [],
            companiesOptions: [],
            costCentre: null,
            costCentreOptions: [],
            countriesOptions: [],
        }
    },
    watch: {
        'companyBillingInfoCopy.email': {
            handler() {
                if (this.companyBillingInfoCopy.email) {
                    // this.fetchOptionsCompanies({ searchTerm: this.companyBillingInfoCopy.email })
                    this.getCompanyByEmail()
                }
            },
            immediate: true
        },
        'customerBillingInfoCopy.phone': {
            handler() {
                this.callPartValidation('phone')
            }
        },
        'companyBillingInfoCopy.companyName': {
            handler() {
                this.prepareCompanyData()
            },
            deep: true
        },
        'costCentre': {
            handler() {
                this.prepareCostCentre()
            },
            deep: true
        },
    },
    mounted() {
        this.axiosGetCountries()
    },
    methods: {
        /**
         * GET customers by name
         * @param {object} searchData - example { searchTerm: 'text' }
         */
        fetchOptionsCustomers(searchData) {
            // console.log('fetchOptionsCustomers', searchData)
            if (!this.adminToken) return false
            if (!searchData.searchTerm) return false
            this.axios.get(this.$config.API_URL_V1 + 'customers?'
                + 'search=' + searchData.searchTerm
                + '&adminToken=' + this.adminToken, this.$config.HEADERS).then(res => {
                // console.log('fetchOptionsCustomers res', res)
                if (res.data?.data?.length) {
                    this.customersOptions = res.data?.data?.map(user => user.name)
                } else {
                    this.customersOptions.length = 0
                    this.customersOptions.push(searchData.searchTerm)
                }
            }).finally(() => {
                //
            })
        },
        /**
         * GET customers by name
         * @param {object} searchData - example { searchTerm: 'text' }
         */
        fetchOptionsEmail(searchData) {
            // console.log('fetchOptionsCustomers', searchData)
            if (!this.adminToken) return false
            if (!searchData.searchTerm) return false
            this.axios.get(this.$config.API_URL_V1 + 'customers'
                + '?search=' + searchData.searchTerm
                + '&adminToken=' + this.adminToken, this.$config.HEADERS).then(res => {
                // console.log('fetchOptionsCustomers', res)
                if (res.data?.data?.length) {
                    this.emailOptions = res.data?.data?.map(user => user.email)
                } else {
                    this.emailOptions.length = 0
                    this.emailOptions.push(searchData.searchTerm)
                }
            }).finally(() => {
                // searchData.loading(false)
            })
        },
        fetchOptionsCompanies(searchData) {
            // console.log('fetchOptionsCustomers', searchData)
            if (!this.adminToken) return false
            if (!searchData.searchTerm) return false
            //  + '&relations[]=cost_centrums.address&relations[]=address'
            this.axios.get(this.$config.API_URL_V1 + 'companies'
                + '?search=' + searchData.searchTerm
                + '&relations[]=cost_centrums.address'
                + '&relations[]=address'
                + '&adminToken=' + this.adminToken,
                this.$config.HEADERS).then(res => {
                console.log('fetchOptionsCompanies', res)
                if (res.data?.data?.length) {
                    this.companiesOptions = res.data?.data?.map(company => {
                        this.costCentreOptions = company.cost_centrums?.map(costCentre => costCentre.name)
                        return company.name
                    })
                } else {
                    this.companiesOptions.length = 0
                    this.companiesOptions.push(searchData.searchTerm)
                }
            }).finally(() => {
                // searchData.loading(false)
            })
        },
        prepareCompanyData() {
            if (!this.adminToken) return false
            this.axios.get(this.$config.API_URL_V1 + 'companies'
                + '?search=' + this.companyBillingInfoCopy.companyName
                + '&relations[]=cost_centrums.address'
                + '&relations[]=address'
                + '&adminToken=' + this.adminToken,
                this.$config.HEADERS).then(res => {
                console.log('prepareCompanyData', res)
                if (res.data?.data?.length) {
                    // this.companiesOptions = res.data?.data?.map(user => user.name)

                    if (res.data?.data?.length === 1) {
                        this.setCompanyData(res.data?.data[0])
                    }
                }
            }).finally(() => {
                // searchData.loading(false)
            })
        },
        prepareCostCentre() {
            if (!this.adminToken) return false
            this.axios.get(this.$config.API_URL_V1 + 'companies'
                + '?search=' + this.companyBillingInfoCopy.companyName
                + '&relations[]=cost_centrums.address'
                + '&relations[]=address'
                + '&adminToken=' + this.adminToken,
                this.$config.HEADERS).then(res => {
                console.log('prepareCompanyData', res)
                if (res.data?.data?.length) {
                    // this.companiesOptions = res.data?.data?.map(user => user.name)

                    if (res.data?.data?.length === 1) {
                        const costCentrum = res.data?.data[0]?.cost_centrums?.filter(centre => centre.name === this.costCentre)
                        this.setCompanyData(costCentrum[0], false)
                    }
                }
            }).finally(() => {
                // searchData.loading(false)
            })
        },
        getCompanyByEmail() {
            if (!this.adminToken) return false
            this.axios.get(this.$config.API_URL_V1 + 'companies'
                + '?search=' + this.companyBillingInfoCopy.email
                + '&relations[]=cost_centrums.address'
                + '&relations[]=address'
                + '&adminToken=' + this.adminToken,
                this.$config.HEADERS).then(res => {
                console.log('getCompanyByEmail', res)
                if (res.data?.data?.length) {
                    if (!this.companyBillingInfoCopy.enterBillingInformation) this.addBillingInformation()
                    this.companiesOptions = res.data?.data?.map(user => user.name)

                    if (res.data?.data?.length === 1) {
                        this.setCompanyData(res.data?.data[0])
                    }
                }
            }).finally(() => {
                // searchData.loading(false)
            })
        },
        setCompanyData(company, setCompany = true) {
            // console.log('setCompanyData', company)

            if (setCompany) {
                this.companyBillingInfoCopy.companyName = company?.name
                this.costCentreOptions = company.cost_centrums?.map(costCentre => costCentre.name)
            }

            if (company?.address) {
                this.customerBillingInfoCopy.street = company.address.street
                this.customerBillingInfoCopy.zipCode = company.address.zip_code
                this.customerBillingInfoCopy.city = company.address.city
                this.customerBillingInfoCopy.phone = company.address.phone
                this.customerBillingInfoCopy.countryId = this.countriesOptions.filter(country => country.id === company.address.country_id)[0]

                this.companyBillingInfoCopy.businessId = company.address.business_id
                this.companyBillingInfoCopy.taxId = company.address.tax_id
                this.companyBillingInfoCopy.vatId = company.address.vat_id
            }
        },
        checkSummarization() {
            console.log('check')
            this.v$.$touch()
            if (this.v$.$invalid) {
            console.log(this.v$)
                this.$nextTick(() => {
                    this.scrollToError()
                })
                return false
            }

            this.$emit('next-step')

            // this.$refs.ref_billing_information.validateFormData()

            // this.$nextTick(() => {
                // let validateLocationStates = []

                // validateLocationStates = [...validateLocationStates, ...[this.v$.$invalid]]

                // validate billing information
                // validateLocationStates = [...[this.$refs.ref_billing_information.v$.$invalid]]

                // const errorExist = validateLocationStates.some(item => item)
                // if (!errorExist) {
                //     this.$emit('next-step')
                // }
            // })
        },
        getCompanyData() {
            if (!this.adminToken) return false
            this.axios.get(this.$config.API_URL_V1 + 'companies'
                + '?search=' + this.companyBillingInfoCopy.email
                + '&relations[]=cost_centrums.address'
                + '&relations[]=address'
                + '&adminToken=' + this.adminToken
                , this.$config.HEADERS).then(res => {
                console.log('getCompanyData', res)

                const companyData = res.data.data[0]
                if (res.data.data?.length === 1) {
                    this.setCompanyData(companyData)
                }

                // this.companiesOptions =
            }).finally(() => {
                // searchData.loading(false)
            })
        },

        addBillingInformation() {
            const accordionGroup = document.querySelector('#add-billing-information-group');

            if (this.companyBillingInfoCopy.enterBillingInformation) {
                this.companyBillingInfoCopy.enterBillingInformation = false
                accordionGroup.value = undefined;
            } else {
                this.companyBillingInfoCopy.enterBillingInformation = true
                accordionGroup.value = 'billingInformation';
            }
        },
        axiosGetCountries() {
            this.axios.get(this.$config.API_URL_V1 + 'countries?perPage=999', this.$config.HEADERS)
                .then(res => {
                    // console.log('axiosGetCountries', response)
                    this.countriesOptions = res.data.data.map(country => {
                        const { id, code, title } = country
                        // if (code === 'SK' && !this.selected) this.selectedCopy = id // set default country id by code SK

                        return {
                            code,
                            id,
                            title
                        }
                    })
                }, (error) => {
                    // this.selectSettings['disabled'] = true // disable input for country
                    // this.selectedCopy = '006ffb7f-e5ca-4ed0-8f53-f6738c77c7d6' // default id for SK
                    console.warn(error.response.data)
                }).finally(() => {
                    //
                })
        },
        callPartValidation(key) {
            this.v$.customerBillingInfoCopy[key].$touch()
        },
        scrollToError() {
            const errorContainer = document.querySelector('.validation-error-message')?.parentNode
            if (errorContainer) this.$parent.$parent.$parent.$parent.scrollToContentPosition()
        },
        prefillData(data) {
            this.customerBillingInfoCopy.city = data?.address?.city || ''
            this.customerBillingInfoCopy.street = data?.address?.street || ''
            this.customerBillingInfoCopy.zipCode = data?.address?.zip_code || ''
            this.customerBillingInfoCopy.countryId = data?.address?.country || ''
        }
    },
    computed: {
        customerBillingInfoCopy() {
            return this.customerBillingInfo
        },
        companyBillingInfoCopy: {
			get() {
				return this.companyBillingInfo
			},
			set(value) {
				this.$emit('update:company-billing-info', value)
			}
		},
        validationError() {
            return (dataKey,key) => !!(this.v$[dataKey][key].$errors.length)
        },
        validationMessage() {
            return (dataKey,key) => {
                const errorKey = this.v$[dataKey][key].$errors[0].$validator
                return '$vForm.' + key + '.' + errorKey
            }
        },
        adminLogged() {
            return !!this.adminToken?.length
        }
    },
    validations () {
        return {
            customerBillingInfoCopy: {
                firstName: {
                    required
                },
                street: {
                    required
                },
                zipCode: {
                    required,
                    minLength: minLength(5),
                    maxLength: maxLength(6)
                },
                city: {
                    required
                },
                phone: {
                    required,
                    phoneIsAccepted: phoneIsAccepted
                },
                countryId: {
                    required
                },
            },
            companyBillingInfoCopy: {
                email: {
                    email,
                    required
                },
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.order-billing-information-v2::v-deep {

    .section {
        position: relative;

        &--1 {
            position: relative;
            z-index: 1;

            > :first-child {
                position: relative;
                z-index: 1;
            }

            > :last-child {
                position: relative;
                z-index: 0;
            }
        }

        &--2 {
            z-index: 0;
        }
    }

    .billing-customer-info-wrapper {
        position: relative;
        z-index: 10;

        @media (min-width: 992px) {
            display: flex;
            gap: 10px;
        }
    }

    #add-billing-information-group {
        @media (min-width: 992px) {
            .section {
                display: flex;
                align-items: flex-start;
                gap: 10px;
            }
        }
    }

    .address-wrapper {
        @media (min-width: 992px) {
            display: flex;
            align-items: flex-start;
            gap: 10px;
        }
    }
}
</style>