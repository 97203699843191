export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                /**
                 * Create options for select
                 * @param {number} countOptions - count of options
                 * @param {boolean} deleteFirstItem - delete option with value 0
                 * @return {array} - example: [{value: 0}, {value: 1}, {value: 2},...]
                 */
                createOptions(countOptions, deleteFirstItem = false) {
                    return new Promise(resolve => {
                        let newOptions = []
                        for (let i = 0; i < countOptions; i++) {
                            let option = {}
                            option['value'] = i
                            newOptions.push(option)
                        }
                        if (deleteFirstItem) newOptions.shift()
                        resolve(newOptions)
                    })
                },
            }
        })
    }
}