<template>
    <ion-app>
<!--        <ion-page class="page">-->
            <ion-content ref="ref_app_order_form_page" :fullscreen="true">
               <CreateNewOrder
                   :user="user"
                   :platform-is-app="platformIsApp"
                   @scroll-to-position="scrollToContentPosition"
               />
            </ion-content>
<!--        </ion-page>-->
    </ion-app>
</template>

<script>
import {IonApp} from '@ionic/vue';
import { defineComponent } from 'vue';
import CreateNewOrder from "@/components/CreateNewOrder";
import { Capacitor } from '@capacitor/core';

export default defineComponent({
    name: 'App',
    components: {
        CreateNewOrder,
        IonApp
    },
	data() {
		return {
			user: {}
		}
	},
    setup() {
        // @ts-ignore
        const platformIsApp = Capacitor.getPlatform() !== 'web'
        // console.log('platformIsApp', platformIsApp)
        return {
            platformIsApp
        }
    },
    mounted() {
		this.getLocalStorage()

        document.addEventListener('deviceready', function () {
            window.open = window.cordova.InAppBrowser.open
        })
	},
    methods: {
		getLocalStorage() {
			if (!window.localStorage.carsenApp) return;
			
			const parsedData = JSON.parse(window.localStorage.carsenApp)

            // set the logg in user in the app
            this.user = parsedData.user?.profileUserData || {}
            // set the app language
			this.$i18n.locale = parsedData.app?.lang || 'en'
		},
        /**
         * Scroll to specific Y position
         * @param {number} positionY - position on the Y axis
         */
        scrollToContentPosition(positionY = 0) {
            const errorEl = document.querySelector('.validation-error-message')?.parentNode

            if (this.platformIsApp) {
                let scrollTo = 0
                errorEl ? scrollTo = errorEl.offsetTop : scrollTo = positionY
                this.$refs.ref_app_order_form_page.$el.scrollToPoint(0, scrollTo, 500)
            } else {
                if (errorEl) {
                    errorEl.scrollIntoView({ behavior: 'smooth' })
                } else {
                    const wrapperEl = document.querySelector('#carsen-order-form-container')
                    if (wrapperEl) wrapperEl.scrollIntoView({ behavior: 'smooth' })
                }
            }
        },
		
		async openExternalModal(body) {
			return new Promise((res, rej) => {
				window.postMessage(body, '*')
				
				function modalCallback(e) {
					if (e.data.type === 'modal-response') {
						console.log('modal response', e.data)
						
						res(e)
						
						window.removeEventListener('message', modalCallback)
					} else if (e.data.type === 'modal-reject') {
						console.log('modal reject', e.data)
						
						rej(e)
						
						window.removeEventListener('message', modalCallback)
					}
				}
				
				window.addEventListener('message', modalCallback)
			})
		}
    }
});
</script>