<template>
    <div class="the-location" :class="{ 'additional-class': locationIndex !== 0 }">

        <LocationHeader
            v-if="locationIndex !== 0"
            :type="type"
            :location-index="locationIndex"
            @delete-location="deleteLocation"
        />

        <div class="global-form-wrapper">
            <div class="input-wrapper input-background-gray">
                <div class="label">{{ componentData.inputPlaceLabel }} *</div>
                <div class="input-container">
                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/nastup.svg`" alt=" " />
                    <SearchInput
                        label="place"
                        v-model:selected="locationCopy.boardingPlace"
                        :select-options="placeOptions"
                        @fetch-options="fetchOptionsPlace"
                    />
                </div>
                <ErrorMessage v-if="validationError('boardingPlace')" :tKey="validationMessage('boardingPlace')" />
            </div>

            <div class="inline-wrapper">
                <div class="input-wrapper">
                    <div class="label">{{ $t('location.passengers') }} *</div>
                    <NumberStepper
                        :count="locationCopy.passengersCount"
                        @decrease-number="decreaseNumber"
                        @increase-number="increaseNumber"
                    />
                </div>

                <div>
                    <div class="input-wrapper" v-if="componentData.inputDateShow && locationIndex === 0">
                        <div class="label">{{ $t('dateAndTime') }} *</div>

                        <div class="input-container datetime-wrapper">
                            <Datepicker
                                v-model="locationCopy.date"
                                :min-date="minSelectDate"
                                format="dd.MM.yyyy"
                                locale="sk-SK"
                                :alt-position="true"
                                :enable-time-picker="false"
                                :auto-apply="true"
                            >
                                <template #input-icon>
                                    <img class="icon" :src="`${$baseUrl}/assets/form/icons/datum.svg`" alt=" " />
                                </template>
                            </Datepicker>

                            <Datepicker
                                v-model="locationCopy.time"
                                format="HH:mm"
                                locale="sk-SK"
                                :minutes-increment="10"
                                :minutes-grid-increment="10"
                                :alt-position="true"
                                :auto-apply="false"
                                time-picker
                                :select-text="$t('select')"
                                :cancel-text="$t('cancel')"
                            >
                                <template #input-icon>
                                    <img class="icon" :src="`${$baseUrl}/assets/form/icons/cas.svg`" alt=" " />
                                </template>
                            </Datepicker>
                        </div>
                        <ErrorMessage v-if="validationError('date')" :tKey="validationMessage('date')" />
                        <ErrorMessage v-if="validationError('time')" :tKey="validationMessage('time')" />
                    </div>
                </div>
            </div>

            <div class="input-wrapper">
                <div class="label">{{ $t('location.name') }} *</div>
                <div class="input-container">
                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/meno.svg`" alt=" " />
                    <input
                        class="input input--with-icon"
                        v-model="locationCopy.name"
                        name="name"
                    />
                </div>
                <ErrorMessage v-if="validationError('name')" :tKey="validationMessage('name')" />
            </div>

            <div class="in-cols-2-gap-10">
                <div class="input-wrapper">
                    <div class="label">{{ $t('location.phone') }} *</div>
                    <div class="input-container">
                        <PhoneInput
                            class="input--tel"
                            v-model:phone-number="locationCopy.tel"
                            v-model:phone-validation="phoneValidation"
                        />
                    </div>
                    <ErrorMessage v-if="validationError('tel')" :tKey="validationMessage('tel')" />
                </div>

                <div class="input-wrapper">
                    <div class="label">{{ $t('location.email') }} *</div>
                    <div class="input-container">
                        <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/mail.svg`" alt=" " />
                        <input
                            class="input input--with-icon"
                            v-model="locationCopy.email"
                            name="email"
                        />
                    </div>
                    <ErrorMessage v-if="validationError('email')" :tKey="validationMessage('email')" />
                </div>
            </div>

        </div>

        <ion-list lines="none" class="general-form-container">
<!--            <div class="input-wrapper input-destination">-->
<!--                <ion-label position="stacked" class="label input-destination__label">{{ componentData.inputPlaceLabel }} *</ion-label>-->
<!--                <SearchInput-->
<!--                    class="input-destination__input"-->
<!--                    v-model:selected="locationCopy.boardingPlace"-->
<!--                    :icon-type="type === 'there' ? 'nastup' : 'ciel'"-->
<!--                />-->
<!--                <ErrorMessage v-if="validationError('boardingPlace')" :tKey="validationMessage('boardingPlace')" />-->
<!--            </div>-->

<!--            <ion-item class="input-wrapper input-lastname">-->
<!--                <ion-label position="stacked" class="label input-lastname__label">{{ $t('location.lastname') }}</ion-label>-->
<!--                <ion-input-->
<!--                    class="input input-lastname__input"-->
<!--                    name="family-name" autocomplete="family-name" type="text" :maxlength="255" :autocapitalize="'on'"-->
<!--                    v-model="locationCopy.lastname"-->
<!--                    @ionChange="callPartValidation('lastname')"-->
<!--                ></ion-input>-->
<!--                <ErrorMessage v-if="validationError('lastname')" :tKey="validationMessage('lastname')" />-->
<!--            </ion-item>-->

<!--            <ion-item class="input-wrapper input-passengers" :class="{'input-shrink': componentData.inputDateShow && locationIndex === 0}">-->
<!--                <ion-label position="stacked" class="label input-passengers__label">{{ $t('location.passengers') }}</ion-label>-->
<!--                <NumberStepper-->
<!--                    :count="locationCopy.passengersCount"-->
<!--                    @decrease-number="decreaseNumber"-->
<!--                    @increase-number="increaseNumber"-->
<!--                />-->
<!--            </ion-item>-->

<!--            <ion-row class="date-time-wrapper" v-if="componentData.inputDateShow && locationIndex === 0">-->
<!--                <ion-col size="12" class="date-time-title">{{ $t('dateAndTime') }}:*</ion-col>-->
<!--                <ion-col size="6">-->
<!--                    <Datepicker-->
<!--                        v-model="locationCopy.date"-->
<!--                        :min-date="minSelectDate"-->
<!--                        format="dd.MM.yyyy"-->
<!--						locale="sk-SK"-->
<!--                        :alt-position="true"-->
<!--                        :enable-time-picker="false"-->
<!--                        :auto-apply="true"-->
<!--                    >-->
<!--                        <template #input-icon>-->
<!--                            <img class="icon" :src="`${$baseUrl}/assets/form/icons/datum.svg`" alt=" " />-->
<!--                        </template>-->
<!--                    </Datepicker>-->
<!--                </ion-col>-->
<!--                <ion-col size="6">-->
<!--                    <Datepicker-->
<!--                        v-model="locationCopy.time"-->
<!--                        format="HH:mm"-->
<!--						locale="sk-SK"-->
<!--                        :minutes-increment="10"-->
<!--                        :minutes-grid-increment="10"-->
<!--                        :alt-position="true"-->
<!--                        :auto-apply="false"-->
<!--                        time-picker-->
<!--						:select-text="$t('select')"-->
<!--						:cancel-text="$t('cancel')"-->
<!--                    >-->
<!--                        <template #input-icon>-->
<!--                            <img class="icon" :src="`${$baseUrl}/assets/form/icons/cas.svg`" alt=" " />-->
<!--                        </template>-->
<!--                    </Datepicker>-->
<!--                </ion-col>-->
<!--                <ion-col size="12" class="error-wrapper">-->
<!--                    <ErrorMessage v-if="validationError('date')" :tKey="validationMessage('date')" />-->
<!--                    <ErrorMessage v-if="validationError('time')" :tKey="validationMessage('time')" />-->
<!--                </ion-col>-->
<!--            </ion-row>-->

<!--            <ion-item class="input-wrapper input-name">-->
<!--                <ion-label position="stacked" class="label input-name__label">{{ $t('location.name') }} *</ion-label>-->
<!--                <div class="input-container">-->
<!--                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/meno.svg`" alt=" " />-->
<!--                    <ion-input-->
<!--                        class="input input-name__input"-->
<!--                        name="name" autocomplete="name" type="text" :maxlength="255" :autocapitalize="'on'"-->
<!--                        v-model="locationCopy.name"-->
<!--                        @ionChange="callPartValidation('name')"-->
<!--                    ></ion-input>-->
<!--                </div>-->
<!--                <ErrorMessage v-if="validationError('name')" :tKey="validationMessage('name')" />-->
<!--            </ion-item>-->

<!--            <ion-item class="input-wrapper input-tel" lines="none">-->
<!--                <ion-label class="label input-tel__label" position="stacked">{{ $t('location.phone') }} *</ion-label>-->
<!--                <PhoneInput class="input-tel__input" v-model:phone-number="locationCopy.tel" v-model:phone-validation="phoneValidation" />-->
<!--                <ErrorMessage v-if="validationError('tel')" :tKey="validationMessage('tel')" />-->
<!--            </ion-item>-->

<!--            <ion-item class="input-wrapper input-email">-->
<!--                <ion-label position="stacked" class="label input-email__label">{{ $t('location.email') }} *</ion-label>-->
<!--                <div class="input-container">-->
<!--                    <img class="input-icon" :src="`${$baseUrl}/assets/form/icons/mail.svg`" alt=" " />-->
<!--                    <ion-input-->
<!--                        class="input input-email__input"-->
<!--                        name="email" autocomplete="email" type="email" :maxlength="255"-->
<!--                        v-model="locationCopy.email"-->
<!--                        @ionChange="callPartValidation('email')"-->
<!--                    ></ion-input>-->
<!--                </div>-->
<!--                <ErrorMessage v-if="validationError('email')" :tKey="validationMessage('email')" />-->
<!--            </ion-item>-->

            <ion-item class="input-wrapper input-confirmation">
                <ion-label class="label input-confirmation__label">{{ $t('location.sendConfirm') }}</ion-label>
                <ion-checkbox
                    class="checkbox input-confirmation__input"
                    slot="start"
                    v-model="locationCopy.sendConfirmation"
                ></ion-checkbox>
            </ion-item>
        </ion-list>
    </div>
</template>

<script>
import NumberStepper from "@/components/elements/NumberStepper";
import LocationHeader from "@/components/elements/LocationHeader";
import useVuelidate from '@vuelidate/core'
import { required, email, requiredIf } from '@vuelidate/validators'
import ErrorMessage from "../../common/ErrorMessage.vue";
import PhoneInput from "../../elements/PhoneInput.vue";
import SearchInput from "./SelectInput.vue";
import { timeOutline, calendarOutline } from "ionicons/icons"

// eslint-disable-next-line
const phoneIsAccepted = (value, {}, vm) => {
    return !!(
        value.length
        && vm.phoneValidation
        && vm.phoneValidation.valid
    );
}

// eslint-disable-next-line
const minSelectedDate = (value, {}, vm) => {
    if (vm.componentData.inputDateShow && vm.locationIndex === 0) {
        // const currentDate = vm.$moment(Date.now())
        // const selectedDate = vm.$moment(value)
        // return selectedDate.diff(currentDate) > 0
        return true
    } else {
        return true
    }
}

export default {
    name: "TheLocation",
    components: {
        SearchInput,
        PhoneInput,
        ErrorMessage,
        LocationHeader,
        NumberStepper,
    },
    props: {
        /**
         * Type of
         * @value there - way there
         * @value back - way back
         */
        type: {
            type: String,
            default: ''
        },
        /**
         * Location order index
         */
        locationIndex: {
            type: Number,
            default: 0
        },
        /**
         * Location data
         */
        location: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    setup () {
        return {
            v$: useVuelidate(),
        }
    },
    data() {
        return {
            timeOutline, calendarOutline,

            phoneValidation: null,
            componentData: {},

            placeOptions: []
        }
    },
    mounted() {
        this.setComponentData()
    },
    watch: {
        'locationCopy.boardingPlace': {
            handler() {
                this.callPartValidation('boardingPlace')
            }
        },
        'locationCopy.tel': {
            handler() {
                if (this.locationCopy.tel) this.callPartValidation('tel')
            }
        },
    },
    methods: {
        fetchOptionsPlace(searchData) {
            if (!searchData.searchTerm) return false

            let apiURL = this.$config.API_URL_V1 + 'googlemaps/autocomplete'
            apiURL += '?searchParam=' + searchData.searchTerm

            this.axios.get(apiURL, this.$config.HEADERS).then(response => {
                // console.log('getCountries', response)
                this.placeOptions = response.data
            }, (error) => {
                console.warn(error.response.data)
            }).finally(() => {
                //
            })
        },
        setComponentData() {
            const dataThere = {
                inputPlaceLabel: this.$t('startLocation'),
                inputDateShow: true
            }

            const dataBack = {
                inputPlaceLabel: this.$t('endLocation'),
                inputDateShow: false
            }

            this.type === 'there'
                ? this.componentData = dataThere
                : this.componentData = dataBack
        },
        decreaseNumber() {
            this.locationCopy.passengersCount -= 1
        },
        increaseNumber() {
            this.locationCopy.passengersCount += 1
        },
        deleteLocation(index) {
            this.$emit('delete-location', index)
        },
        validateFormData() {
            this.v$.$touch()
            if (this.v$.$invalid) {
                this.$nextTick(() => {
                    // this.scrollToError()
                })
                return false
            }
        },
        scrollToError() {
            const errorContainer = document.querySelector('.validation-error-message')?.parentNode
            if (errorContainer) this.$parent.$parent.$parent.$parent.$parent.$parent.scrollToContentPosition()
        },
        callPartValidation(key) {
            this.v$.locationCopy[key].$touch()
        }
    },
    computed: {
        locationCopy: {
            get() {
                return this.location
            },
            set(value) {
                this.$emit('update:location', value)
            }
        },
        validationError() {
            return key => !!(this.v$.locationCopy[key].$errors.length)
        },
        validationMessage() {
            return key => {
                const errorKey = this.v$.locationCopy[key].$errors[0].$validator
                return '$vForm.' + key + '.' + errorKey
            }
        },
        minSelectDate() {
            let currentDate = new Date();
            return this.$moment(currentDate.setDate(currentDate.getDate())).format('YYYY-MM-DD')
        }
    },
    validations () {
        return {
            locationCopy: {
                name: {
                    required
                },
                boardingPlace: {
                    required
                },
                date: {
                    required: requiredIf(() => { return this.componentData.inputDateShow && this.locationIndex === 0 }),
                    minSelectedDate: minSelectedDate
                },
                time: {
                    required: requiredIf(() => { return this.componentData.inputDateShow && this.locationIndex === 0 })
                },
                tel: {
                    required,
                    phoneIsAccepted: phoneIsAccepted
                },
                email: {
                    email,
                    required
                },
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.the-location::v-deep {
    &.additional-class {
        margin-top: 30px;
    }

    .inline-wrapper {
        @media (min-width: 800px) {
            display: flex;
            gap: 10px;

            > :first-child {
                flex-basis: calc(100%/3);
            }

            > :last-child {
                flex-basis: calc(100%/3*2);
            }
        }
    }
}
</style>