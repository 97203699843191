<template>
    <div class="component-location-header">
        <div class="location-header">
            <div class="location-title">
                <div class="location-title__index">{{ sequenceItem }}.</div>
                &nbsp;
                <div class="location-title__text">{{ headerTitle }}</div>
            </div>
            <div v-if="sequenceItem !== 1" class="delete-location" @click="deleteLocation">
                <ion-icon :icon="trashOutline" alt=" " />
            </div>
        </div>
    </div>
</template>

<script>
import { trashOutline } from "ionicons/icons"
import {alertController} from "@ionic/vue";

export default {
    name: "LocationHeader",
    props: {
        /**
         * Location order index
         */
        locationIndex: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            trashOutline,
        }
    },
    methods: {
        async deleteLocation() {
			if (this.$root.platformIsApp) {
				const alert = await alertController.create({
					header: this.$t('location.deleteConfirm'),
					mode: 'ios',
					buttons: [
						{
							text: this.$t('no'),
							role: 'cancel',
							handler: () => {
								return false
							},
						},
						{
							text: this.$t('delete'),
							role: 'confirm',
							handler: () => {
								this.$emit('delete-location', this.locationIndex)
							},
						},
					],
				});
				await alert.present();
				
			} else {
				// web modal
				await this.$root.openExternalModal({
					type: 'modal',
					title: this.$t('location.deleteConfirm'),
					cancelText: this.$t('no'),
					okText: this.$t('delete')
				})
					.then(() => {
						this.$emit('delete-location', this.locationIndex)
					})
			}
        },
    },
    computed: {
        sequenceItem() {
            return this.locationIndex + 1
        },
        headerTitle() {
            if (this.type === 'there') {
                return this.$t('start')
            } else {
                return this.$t('destination')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-location-header::v-deep {
    .location-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .location-title {
            display: flex;
            align-items: center;

            font-size: 16px;
            font-weight: 600;
        }
    }
}
</style>