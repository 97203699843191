<template>
    <div class="component-child-seats">

        <div
            v-for="(seat, index) in childSeatsCopy" :key="index"
            class="select-child-seat-wrapper"
        >
            <ion-item mode="ios" class="input-wrapper select-suitcases">
                <ion-label class="label select-suitcases__label">{{ $t('childSeat') }}</ion-label>
<!--                <ion-select-->
<!--                    class="select-suitcases__select"-->
<!--                    v-model="childSeatsCopy[index].age"-->
<!--                    interface="alert"-->
<!--                    :interface-options="sheetOptions"-->
<!--                    :placeholder="$t('childSeats.age')" :cancelText="$t('cancelSelection')"-->
<!--                >-->
<!--                    <ion-select-option-->
<!--                        v-for="item in options" :key="item.value"-->
<!--                        :value="item.value"-->
<!--                    >-->
<!--                        {{ item.value }}-->
<!--                    </ion-select-option>-->
<!--                </ion-select>-->
				<div class="custom-select-wrap">
					<select
						class="select"
						v-model="childSeatsCopy[index].age"
					>
						<option :value="0" disabled>{{ $t('childSeats.age') }}</option>
						<option
							v-for="item in options" :key="item.value"
							:value="item.value"
						>{{ item.value }}</option>
					</select>
				</div>
				
            </ion-item>
            <div class="delete-child-seat">
                <ion-icon :src="trashOutline" alt=" " @click="deleteChildSeat(index)"></ion-icon>
            </div>
        </div>
        <ErrorMessage v-if="validationError" :tKey="'$vForm.childSeatsCopy.age'" />

        <div class="add-button" @click="addChildSeat">
            <span class="btn-content">
                <span class="btn-content__label">{{ $t('childSeats.add') }}</span>
                <img class="btn-content__image" :src="`${$baseUrl}/assets/form/icons/sedacka.svg`" alt=" " />
            </span>
        </div>

    </div>
</template>

<script>
import { trashOutline } from "ionicons/icons";
import {alertController} from "@ionic/vue";
import useVuelidate from "@vuelidate/core";
import {helpers, required, minValue} from '@vuelidate/validators';
import ErrorMessage from "../common/ErrorMessage";

export default {
    name: "ChildSeats",
    components: {
        ErrorMessage
    },
    props: {
        childSeats: {
            type: Array,
            default() {
                return []
            }
        },
    },
    setup () {
        return {
            v$: useVuelidate(),
        }
    },
    data() {
        return {
            trashOutline,

            sheetOptions: {
                header: '',
            },
            countOptions: 11,
            options: [],
        }
    },
	created() {
		this.sheetOptions.header = this.$t('childSeats.chooseAge')
	},
    mounted() {
        this.createChildSeatsOptions()
    },
    methods: {
        async createChildSeatsOptions() {
            this.options = await this.createOptions(this.countOptions, true)
        },
        addChildSeat() {
            this.$emit('add-child-seat')
        },
        async deleteChildSeat(index) {
			if (this.$root.platformIsApp) {
				const alert = await alertController.create({
					header: this.$t('childSeats.deleteConfirm'),
					buttons: [
						{
							text: this.$t('no'),
							role: 'cancel',
							handler: () => {
								return false
							},
						},
						{
							text: this.$t('delete'),
							role: 'confirm',
							handler: () => {
								this.$emit('delete-child-seat', index)
							},
						},
					],
				});
				await alert.present();
	
			} else {
				// web modal
				await this.$root.openExternalModal({
					type: 'modal',
					title: this.$t('childSeats.deleteConfirm'),
					cancelText: this.$t('no'),
					okText: this.$t('delete')
				})
					.then(() => {
						this.$emit('delete-child-seat', index)
					})
			}
        },
        validateFormData() {
            this.v$.$touch()
            if (this.v$.$invalid) {
                this.$nextTick(() => {
                    this.scrollToError()
                })
                return false
            }
        },
        scrollToError() {
            const errorContainer = document.querySelector('.validation-error-message')?.parentNode
            if (errorContainer) this.$parent.$parent.$parent.$parent.$parent.$parent.scrollToContentPosition()
        },
    },
    computed: {
        childSeatsCopy: {
            get() {
                return this.childSeats
            },
            set(value) {
                this.$emit('update:child-seats', value)
            }
        },
        validationError() {
            return !!(this.v$.childSeatsCopy.$error)
        },
    },
    validations () {
        return {
            childSeatsCopy: {
                $each: helpers.forEach({
                    age: {
                        required,
                        minValue: minValue(1)
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-child-seats::v-deep {
    .select-child-seat-wrapper {
        display: flex;
        align-items: center;

        .select-suitcases {
            width: 100%;
        }

        .delete-child-seat {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 20px;
            aspect-ratio: 1/1;
            margin-left: 10px;
        }
    }
}
</style>