<template>
    <div class="component-road-desc">

        <div class="road-info date-time-info">
            <div class="title">{{ $t('dateAndTime') }}</div>

            <div class="info-wrapper">
                <div class="value-wrapper date-info">
                    <img class="icon" :src="`${$baseUrl}/assets/form/icons/datum_zelena.svg`" alt=" " />
                    <div class="value">{{ formatDate('L') }}</div>
                </div>
                <div class="value-wrapper time-info">
                    <img class="icon" :src="`${$baseUrl}/assets/form/icons/cas_biela.svg`" alt=" " />
                    <div class="value">{{ formatDate('LT') }}</div>
                </div>
            </div>
        </div>

        <div class="road-info from-info">
            <div class="title">{{ $t('startLocation') }}</div>

            <div class="value-wrapper">
                <img class="icon" :src="`${$baseUrl}/assets/form/icons/nastup_zelena.svg`" alt=" " />
                <div class="value">{{ roadData.start_location }}</div>
            </div>
        </div>

        <div class="road-info to-info">
            <div class="title">{{ $t('destinationTrip') }}</div>

            <div class="value-wrapper">
                <img class="icon" :src="`${$baseUrl}/assets/form/icons/ciel_zelena.svg`" alt=" " />
                <div class="value">{{ roadData.end_location }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RoadDesc",
    props: {
        roadData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
        formatDate() {
            return format => this.$moment.unix(this.roadData.start_date).format(format)
        }
    }
}
</script>

<style lang="scss" scoped>
.component-road-desc::v-deep {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 1px 10px;

    .road-info {
        margin: 10px 0;

        .title {
            color: #01AEB6;
            font-size: 13px;
            text-align: left;
            margin-bottom: 3px;
        }

        .value-wrapper {
            display: flex;
            align-items: center;

            .icon {
                margin-right: 5px;
                width: 15px;
                min-width: 15px;
                aspect-ratio: 1;
            }

            .value {
                font-size: 17px;
                font-weight: 600;
            }
        }

        &.date-time-info {
            .info-wrapper {
                display: flex;

                .time-info {
                    background: #01AEB6;
                    border-radius: 5px;
                    color: white;
                    padding: 2px 7px;
                    margin: 0 5px;
                }
            }
        }
    }

    @media (min-width: 900px) {
        display: flex;
        align-items: flex-start;

        .road-info {
            width: calc(100%/3);
        }
    }
}
</style>