<template>
    <div class="create-new-order">

        <OrderWayThere
            v-if="displaySelectedStep(0)"
            :order-data="wayThereData"
            @add-next-stop="addNextStop('there')"
            @delete-location="deleteNextStop($event, 'there')"
            @add-child-seat="addChildSeat('there')"
            @delete-child-seat="deleteChildSeat($event, 'there')"
            @next-step="chooseReturn"
        />

        <OrderWayBack
            v-if="displaySelectedStep(1)"
            :order-data="wayBackData"
            :way-there-data="wayThereData"
            @add-next-stop="addNextStop('back')"
            @delete-location="deleteNextStop($event, 'back')"
            @add-child-seat="addChildSeat('back')"
            @delete-child-seat="deleteChildSeat($event, 'back')"
            @next-step="goNextStep"
        />

        <OrderBillingInformation
            v-if="displaySelectedStep(2)"
            :admin-token="adminToken"
            :customer-billing-info="customerBillingInfo"
            :company-billing-info="companyBillingInfo"
            :loading="loaders.general"
            :error-message="errorMessage"
            @next-step="checkSummary"
        />

        <OrderSummary
            v-if="displaySelectedStep(3)"
            :order-data="createdOrder"
            :summarization="summarizationData"
            :payment-methods="paymentMethods"
            :loading="loaders.general"
            :error-message="errorMessage"
            @go-back="goBack"
            @next-step="createNewOrderWithSave"
        />

        <OrderThankYouPage
            v-if="displaySelectedStep(4)"
            :order-data="createdOrder"
            :loader="loaders.general"
            @payment-repeat="payOrder"
            @change-order-payment="changeOrderPaymentMethod"
        />

        <div class="container handle-buttons-container">
            <ion-button
                mode="ios"
                class="btn btn-outline"
                v-if="canGoBack"
                @click="goBack"
            >
                <span class="btn-content">
                    <ion-icon :icon="chevronBackOutline" class="btn-content__icon" alt=" "></ion-icon>
                    <span class="btn-content__label">{{ $t('back') }}</span>
                </span>
            </ion-button>

            <ion-button
                mode="ios"
                class="btn btn-outline carsen-form-reset"
                v-if="displaySelectedStep(4)"
                @click="setDefaultFormData"
            >
            <span class="btn-content">
                <span class="btn-content__label">{{ $t('createNewOrder') }}</span>
                <ion-icon :icon="addCircleOutline" class="btn-content__icon" alt=" "></ion-icon>
            </span>
            </ion-button>
        </div>

        <div class="errors" v-if="errors">
            <div
                class="error"
                v-for="(error, index) in errors" :key="index"
            >
                <div
                    class="error-message"
                    v-for="(err, index) in error" :key="index"
                >
                    {{ err }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { chevronForwardOutline, chevronBackOutline, addCircleOutline } from "ionicons/icons";
import { alertController } from "@ionic/vue";
import OrderWayThere from "./OrderWayThere";
import OrderWayBack from "./OrderWayBack";
import OrderSummary from "./OrderSummary";
import OrderBillingInformation from "./OrderBillingInformation";
import OrderThankYouPage from "./OrderThankYou";
import Echo from 'laravel-echo'

export default {
    name: "CreateNewOrder",
	props: ['user', 'platformIsApp'],
    components: {
        OrderWayThere,
        OrderBillingInformation,
        OrderThankYouPage,
        OrderSummary,
        OrderWayBack,
    },
    data() {
        return {
            chevronForwardOutline, chevronBackOutline, addCircleOutline,

            // actual step in order
            orderStep: 0,

            locationDefault: {
                id: 0,
                name: '',
                passengersCount: 1,
                boardingPlace: null,
                date: '',
                time: '',
                tel: '',
                email: '',
                sendConfirmation: true
            },
            childSeatDefault: {
                id: 0,
                age: 0
            },

            // step 0 - the way there
            wayThereData: {},

            // step 1 - the way back
            wayBack: false,
            wayBackData: {},

            // step 2 - customer address & billing info
            customerBillingInfo: {},
            companyBillingInfo: {},

            // created order (save: false) for price and travel recalculation
            createdOrder: {},

            // step 3 - summarization
            summarizationData: {},
            paymentMethods: [],

            // payment
            payData: {}, // created payment url
            refInAppBrowser: null,
            paymentData: {}, // payment data from echo
            orderUrlParams: {}, // order_id & transaction_id from url

            loaders: {
                general: false,
                prepareOrder: false,
            },
            errors: null,
            errorMessage: null,

            adminToken: null, // admin token from cookies
        }
    },
    mounted() {
		let self = this
		
        this.setDefaultFormData()

        if (!this.platformIsApp) this.setDefaultThankYouPage()
	
		this.initResize()
	
		window.onpageshow = function() {
			// console.log('on form show', self.platformIsApp, self.orderStep)
			
			if (!self.platformIsApp && (self.orderStep === 3 || self.orderStep === 4)) {
				// console.log('refresh payment')
				self.setDefaultThankYouPage()
			}
		}

        // check and get token from cookies
        if (this.$cookies.isKey('adminToken')) {
            this.adminToken = this.$cookies.get('adminToken')
        } else {
            this.adminToken = null
        }
    },
	watch: {
		'user': {
			handler: function (newUser) {
				if (newUser) this.setLocationsFromStorage(newUser)
			}
		},
        orderStep() {
            this.scrollToPosition()
	
			this.initResize()
        }
	},
    methods: {
		initResize() {
			if (this.platformIsApp) return false
		
			let el = document.querySelector('.create-new-order')
			const resizeObserver = new ResizeObserver((entries) => {
				// console.log(entries)
				el.parentNode.parentNode.style.height = entries[0].contentRect.height + 60 + 'px'
			})
			resizeObserver.disconnect()
			resizeObserver.observe(el)
		},
        /**
         * Set default form data or reset form for new order
         */
        setDefaultFormData() {
            this.orderStep = 0

            this.wayThereData = {
                locations: [],
                childSeats: [],
                tripDestination: null,
                flightNumber: '',
                bigSuitcases: 0,
                smallSuitcases: 0,
                note: '',
            }

            this.wayBack = false
            this.wayBackData = {
                locations: [],
                childSeats: [],
                boardingPlace: null,
                flightNumber: '',
                bigSuitcases: 0,
                smallSuitcases: 0,
                note: '',
                date: '',
                time: '',
            }

            // set default way there location
            this.wayThereData.locations = []
            this.wayBackData.locations = []
            // create non reactive variable
            const nonReactiveDefaultLocation = JSON.parse(JSON.stringify(this.locationDefault))
            // add default location
            this.wayThereData.locations.push(nonReactiveDefaultLocation)
            this.wayBackData.locations.push(nonReactiveDefaultLocation)

            this.customerBillingInfo = {
                firstName: '',
                street: '',
                zipCode: '',
                city: '',
                phone: '',
                countryId: null,
            }
            this.companyBillingInfo = {
                email: '',
                enterBillingInformation: false,
                companyName: '',
                businessId: '',
                taxId: '',
                vatId: '',
            }

            this.createdOrder = {}
            this.setDefaultPaymentMethod()

            this.loaders.general = false
            this.errorMessage = null

            this.payData = {} // data from created link for payment
            this.refInAppBrowser = null
            this.paymentData = {}
            this.orderUrlParams = {}
            this.leaveEcho()

            this.paymentMethods = []

            this.$nextTick(() => this.setLocationsFromStorage(this.user))
        },
        /**
         * If the user is logged in, pre-fill the data from profile
         */
        setLocationsFromStorage(user) {
            // console.log('localstorage user get', user)
            let location = this.wayThereData.locations[0]
            if (user.address?.first_name && user.address?.last_name) location.name = user.address?.first_name + ' ' + user.address?.last_name
            location.tel = user.address?.phone
            location.email = user.email
            location.sendConfirmation = true

            let customerInfo = this.customerBillingInfo
            if (user.address?.first_name && user.address?.last_name) customerInfo.firstName = user.address?.first_name + ' ' +user.address?.last_name
            customerInfo.street = user.address?.street
            customerInfo.zipCode = user.address?.zip_code
            customerInfo.city = user.address?.city
            customerInfo.phone = user.address?.phone
			
			if (user.address?.country?.code) {
				customerInfo.countryId = {
					code: user.address?.country?.code,
					id: user.address?.country?.id,
					title: user.address?.country?.title,
				}
			} else {
				customerInfo.countryId = null
			}


            let companyInfo = this.companyBillingInfo
            companyInfo.email = user.email
            if (user?.companies?.length) {
                const company = user.companies[0]
                companyInfo.email = company.email
                companyInfo.companyName = company.name
                companyInfo.businessId = company.address?.business_id
                companyInfo.taxId = company.address?.tax_id
                companyInfo.vatId = company.address?.vat_id
            }

            this.wayThereData.orderEmail = user.email
        },
        goBack() {
            if (this.orderStep === 2) {
                this.wayBack
                    ? this.goPreviousStep()
                    : this.goPreviousStep(2)
            } else  {
                this.goPreviousStep()
            }
            // this.$nextTick(() => this.scrollToPosition())
        },
        goNextStep(stepCount = 1) {
            this.orderStep = this.orderStep + stepCount
        },
        goPreviousStep(stepCount = 1) {
            this.orderStep = this.orderStep - stepCount
        },
        /**
         * Get payment methods: online/invoice/cash
         */
        getPaymentMethods(companyId) {
            let url = this.$config.API_URL_V1 + 'payment_methods'
            if (companyId) url += '?company=' + companyId

            this.axios.get(url, this.$config.HEADERS)
                .then(response => {
                    // console.log('getPaymentMethods', response.data.data)
                    const data = response.data.data

                    // sort payment methods by sort_order
                    this.paymentMethods = data.sort((a, b) => a.sort_order - b.sort_order)

                    this.setDefaultPaymentMethod()
                })
        },
        /**
         * Set recommended payment method, method contain recommended: true
         */
        setDefaultPaymentMethod() {
            const recommendedMethods = this.paymentMethods.filter(method => method.recommended)
            this.summarizationData.paymentMethod = recommendedMethods[0]
        },
        /**
         * Get query from url (orderUrlParams: order_id and transaction_id) and get order data based on parameters
         */
        async setDefaultThankYouPage() {
            this.loaders.prepareOrder = true

            // parse current url
            const currentUrl = window.location.href
            // const currentUrl = 'http://localhost:8080?order_id=50ac61a2-6577-4aba-b118-be54e47533fd&transaction_id=a58185b0-e49d-40c3-a82e-dd6784792dc0'
            const updatedUrl = currentUrl.replace('?', '&')
            const urlQuery = new URLSearchParams(updatedUrl);
            this.orderUrlParams = {
                order_id: urlQuery.get("order_id"),
                transaction_id: urlQuery.get("transaction_id")
            }
            if (this.orderUrlParams.order_id || this.orderUrlParams.transaction_id) {
                this.checkOrderPaidStatus(this.orderUrlParams.order_id, this.orderUrlParams.transaction_id)
            } else {
                this.loaders.prepareOrder = false
            }
        },
        /**
         * Check order status
         */
        checkOrderPaidStatus(orderId, transactionId) {
            let url = 'orders/summary/' + orderId + '/' + transactionId
            url += '?relations[]=' + 'invoice'
            url += '&relations[]=' + 'payment_method'

            this.axios.get(this.$config.API_URL_V1 + url, this.$config.HEADERS)
                .then(response => {
                    // console.log('checkOrderStatus createdOrder', response.data)
                    this.createdOrder = response.data
                    this.getPaymentMethods(response.data.company_id)
                    this.orderStep = 4
                }, () => {
                    //
                }).finally(() => {
                    this.loaders.prepareOrder = false
                })
        },
        /**
         * Adding another stop to the order
         */
        addNextStop(dataType) {
            // create non reactive variable
            let newLocation = JSON.parse(JSON.stringify(this.locationDefault))

            let existingId = []
            dataType === 'there'
                ? existingId = this.wayThereData.locations.map(location => location.id)
                : existingId = this.wayBackData.locations.map(location => location.id)

            // get the largest ID of all IDs
            newLocation.id = Math.max(...existingId) + 1

            dataType === 'there'
                ? this.wayThereData.locations.push(newLocation)
                : this.wayBackData.locations.push(newLocation)
        },
        async deleteNextStop(index, dataType) {
            dataType === 'there'
                ? this.wayThereData.locations.splice(index, 1)
                : this.wayBackData.locations.splice(index, 1)
        },
        /**
         * Add a child seat to the order
         */
        addChildSeat(dataType) {
            // create non reactive variable
            let newChildSeat = JSON.parse(JSON.stringify(this.childSeatDefault))

            // get all existing IDs from array
            let existingId = []
            dataType === 'there'
                ? existingId = this.wayThereData.childSeats.map(seat => seat.id)
                : existingId = this.wayBackData.childSeats.map(seat => seat.id)

            // get the largest ID of all IDs
            existingId.length
                ? newChildSeat.id = Math.max(...existingId) + 1
                : newChildSeat.id = 0

            dataType === 'there'
                ? this.wayThereData.childSeats.push(newChildSeat)
                : this.wayBackData.childSeats.push(newChildSeat)
        },
        async deleteChildSeat(index, dataType) {
            dataType === 'there'
                ? this.wayThereData.childSeats.splice(index, 1)
                : this.wayBackData.childSeats.splice(index, 1)
        },
        /**
         * Alert controller whether the user also wants back on the trip
         */
        async chooseReturn() {
			if (this.platformIsApp) {
				const alert = await alertController.create({
					header: this.$t('wantWayBack'),
					mode: 'ios',
					backdropDismiss: false,
					buttons: [
						{
							text: this.$t('no'),
							role: 'cancel',
							handler: () => {
								this.wayBack = false;
								this.$nextTick(() => this.decideNextStep(false))
							},
						},
						{
							text: this.$t('yes'),
							role: 'confirm',
							handler: () => {
								this.wayBack = true;
								this.$nextTick(() => this.decideNextStep(true))
							},
						},
					],
				});
				await alert.present();
				
			} else {
				// web modal
				await this.$root.openExternalModal({
					type: 'modal',
					title: this.$t('wantWayBack'),
					cancelText: this.$t('no'),
					okText: this.$t('yes')
				})
					.then(() => {
						this.wayBack = true;
						this.$nextTick(() => this.decideNextStep(true))
					}, () => {
						this.wayBack = false;
						this.$nextTick(() => this.decideNextStep(false))
					})
			}
        },
        /**
         * Prefill data from the trip there for the trip back
         */
        decideNextStep(wayBack) {
            // copy first passenger to billing info
            this.customerBillingInfo.firstName = JSON.parse(JSON.stringify(this.wayThereData.locations))[0].name
            this.customerBillingInfo.phone = JSON.parse(JSON.stringify(this.wayThereData.locations))[0].tel
            if (!Object.keys(this.user).length) this.companyBillingInfo.email = JSON.parse(JSON.stringify(this.wayThereData.locations))[0].email

            if (wayBack) {
                // copy data from way there to way back
                this.wayBackData.locations = JSON.parse(JSON.stringify(this.wayThereData.locations))
                this.wayBackData.boardingPlace = JSON.parse(JSON.stringify(this.wayThereData.tripDestination))

                this.wayBackData.childSeats = JSON.parse(JSON.stringify(this.wayThereData.childSeats))
                this.wayBackData.bigSuitcases = JSON.parse(JSON.stringify(this.wayThereData.bigSuitcases))
                this.wayBackData.smallSuitcases = JSON.parse(JSON.stringify(this.wayThereData.smallSuitcases))

                this.goNextStep()
            } else {
                this.goNextStep(2)
            }
            // this.$nextTick(() => this.scrollToPosition())
        },
        /**
         * Prepare order data for api
         */
        prepareOrderData() {
            return new Promise(resolve => {
                let postData = {}

                // customer address
                let address = {}
                address['first_name'] = this.customerBillingInfo.firstName
                let phoneNumber = this.customerBillingInfo.phone.replace(/\s/g, '')
                address['phone'] = phoneNumber[0] === '+' ? Number(phoneNumber.substring(1)) : Number(phoneNumber)
                address['street'] = this.customerBillingInfo.street
                address['city'] = this.customerBillingInfo.city
                address['zip_code'] = this.customerBillingInfo.zipCode.replace(/\s/g, '')
                address['country_id'] = this.customerBillingInfo.countryId.id

                // company
                address['company'] = this.companyBillingInfo.enterBillingInformation
                address['company_name'] = this.companyBillingInfo.companyName
                address['business_id'] = this.companyBillingInfo.businessId
                address['tax_id'] = this.companyBillingInfo.taxId
                address['vat_id'] = this.companyBillingInfo.vatId

                let customerData = {}
                customerData['email'] = this.companyBillingInfo.email
                customerData['address'] = address
                postData['customer'] = customerData

                // trips
                let tripWayThere = {}
                tripWayThere['small_luggage'] = this.wayThereData.smallSuitcases
                tripWayThere['big_luggage'] = this.wayThereData.bigSuitcases
                tripWayThere['flight_number'] = this.wayThereData.flightNumber || null
                tripWayThere['customer_note'] = this.wayThereData.note || null
                tripWayThere['childrens'] = this.wayThereData.childSeats.map(seat => {
                    return {
                        age: seat.age,
                        child_seat: true,
                    }
                })
                tripWayThere['passengers'] = this.wayThereData.locations.map((location, passengerIndex) => {
                    let phoneNumber = location.tel.replace(/\s/g, '')
                    // const preparedDateTime = this.$moment(location.date).format('YYYY-MM-DD') + ' ' +  location.time
                    const preparedDateTime = this.$moment(location.date).format('YYYY-MM-DD') + ' ' + this.$moment().set({ hour: location.time.hours, minute: location.time.minutes }).format('HH:mm')
                    return {
                        name: location.name,
                        email: location.email,
                        quantity: location.passengersCount,
                        start_date: passengerIndex === 0 ? preparedDateTime : null,
                        send_confirmation: location.sendConfirmation,
                        phone: phoneNumber[0] === '+' ? Number(phoneNumber.substring(1)) : Number(phoneNumber),
                        start_location: location.boardingPlace.place,
                        start_location_place_id: location.boardingPlace.place_id,
                        end_location: this.wayThereData.tripDestination.place,
                        end_location_place_id: this.wayThereData.tripDestination.place_id
                    }
                })

                let trips = []
                trips.push(tripWayThere)

                if (this.wayBack) {
                    let tripWayBack = {}
                    tripWayBack['is_trip_back'] = true
                    tripWayBack['small_luggage'] = this.wayBackData.smallSuitcases
                    tripWayBack['big_luggage'] = this.wayBackData.bigSuitcases
                    tripWayBack['flight_number'] = this.wayBackData.flightNumber || null
                    tripWayBack['customer_note'] = this.wayBackData.note || null
                    tripWayBack['childrens'] = this.wayBackData.childSeats.map(seat => {
                        return {
                            age: seat.age,
                            child_seat: true,
                        }
                    })
                    tripWayBack['passengers'] = this.wayBackData.locations.map((location, passengerIndex) => {
                        let phoneNumber = location.tel.replace(/\s/g, '')
                        // const preparedDateTime = this.$moment(this.wayBackData.date).format('YYYY-MM-DD') + ' ' +  this.wayBackData.time
                        const preparedDateTime = this.$moment(this.wayBackData.date).format('YYYY-MM-DD') + ' ' + this.$moment().set({ hour: this.wayBackData.time.hours, minute: this.wayBackData.time.minutes }).format('HH:mm')
                        return {
                            name: location.name,
                            email: location.email,
                            quantity: location.passengersCount,
                            start_date: passengerIndex === 0 ? preparedDateTime : null,
                            send_confirmation: location.sendConfirmation,
                            phone: phoneNumber[0] === '+' ? Number(phoneNumber.substring(1)) : Number(phoneNumber),
                            start_location: this.wayBackData.boardingPlace.place,
                            start_location_place_id: this.wayBackData.boardingPlace.place_id,
                            end_location: location.boardingPlace.place,
                            end_location_place_id: location.boardingPlace.place_id,
                        }
                    })

                    trips.push(tripWayBack)
                }

                postData['trips'] = trips

                // default data
                const currentUrl = new URL(window.location.href)
                postData['source_url'] = currentUrl.origin

                resolve(postData)
            })
        },
        /**
         * Check the order data and calculate the trip price
         */
        async checkSummary() {
            let postData = await this.prepareOrderData()
            // console.log('prepare data check', postData)
            this.createNewOrder(postData, false)
        },
        /**
         * Create an order with a payment obligation
         */
        async createNewOrderWithSave() {
            let postData = await this.prepareOrderData()
            postData['payment_method_id'] = this.summarizationData.paymentMethod.id
            // console.log('prepare data save', postData)
            this.createNewOrder(postData, true)
        },
        /**
         * POST new order
         * @param {object} postData - order data
         * @param {boolean} saveOrder - determines whether the order should be saved (false: order recalculate, true: order create & save)
         */
        createNewOrder(postData, saveOrder = false) {
            this.loaders.general = true
            this.errorMessage = null
            this.errors = null

            postData['save'] = saveOrder
            this.axios.post(this.$config.API_URL_V1 + 'orders', postData, this.$config.HEADERS)
                .then(response => {
                    // console.log('POST ORDER', saveOrder,  response)
                    const orderData = response.data.data

                    if (saveOrder) {
                        // when the order was created and saved
                        this.orderUrlParams.order_id = orderData.id

                        if (
                            this.summarizationData.paymentMethod.payment_online
                            && !this.summarizationData.paymentMethod.payment_cash
                            && !this.summarizationData.paymentMethod.payment_as_invoice
                        ) {
                            this.payOrder(orderData.id)
                        } else {
                            this.goNextStep()
                            this.loaders.general = false
                        }
                    } else {
                        // the order was sent only to calculate the price
                        this.getPaymentMethods(orderData.company?.id)
                        this.goNextStep()
                        this.loaders.general = false
                    }

                    this.createdOrder = orderData
                }, (error) => {
                    console.warn(error.response.data)
                    this.errors = error.response.data?.errors
                    this.errorMessage = error.response.data.message
                    this.loaders.general = false
                }).finally(() => {
                    //
                })
        },
        /**
         *
         * Create payment url with redirect to payment gateway
         */
        payOrder(orderId) {
            this.axios.post(this.$config.API_URL_V1 + 'orders/pay/' + orderId, {}, this.$config.HEADERS)
                .then(response => {
                    // console.log('createPaymentUrl payData', response.data)
                    const data = response.data.data

                    const paymentUrl = data.payments[0]?.request_url
                    if (!paymentUrl) {
                        setTimeout(() => {
                            this.goThankYouPage()
                        }, 1000)
                        return false
                    }

                    this.payData = data
                    if (this.platformIsApp) {
                        this.connectPayEchoPusher()

                        // open inAppBrowser with payment gateway
                        this.refInAppBrowser = window.open(paymentUrl, '_blank')

                        // inAppBrowser close event
                        const self = this
                        this.refInAppBrowser.addEventListener('exit', function() {
                            self.goThankYouPage()
                        });
                    } else {
                        // add url to history to go back from payment gateway with order id
                        this.orderUrlParams.transaction_id = data.id
                        const backUrl = new URL(window.location.href)
                        window.history.pushState({}, '',backUrl.origin
                            + '?order_id=' + this.orderUrlParams.order_id
                            + '&transaction_id=' + this.orderUrlParams.transaction_id)
                        window.open(paymentUrl, '_self')
                    }
                }, (error) => {
                    console.warn(error.response.data)
                }).finally(() => {
                    this.loaders.general = false
                })
        },
        goThankYouPage() {
            this.checkOrderPaidStatus(this.createdOrder.id, this.payData.id)

            this.orderStep = 4
            // this.scrollToPosition()

            setTimeout(() => {
                this.leaveEcho()
            }, 2000)
        },
        changeOrderPaymentMethod() {
            this.loaders.general = true
            const cashPayment = this.paymentMethods.filter(method => method.payment_cash)[0]
            const orderId = this.createdOrder.id
            if (!orderId) return false

            this.axios.post(this.$config.API_URL_V1 + 'orders/' + orderId, {
                'payment_method_id': cashPayment.id
            }, this.$config.HEADERS)
                .then(() => {
                    // console.log('changeOrderPaymentMethod', response)

                    const transactionId = this.orderUrlParams.transaction_id || this.payData.id
                    this.checkOrderPaidStatus(orderId, transactionId)
                }, (error) => {
                    console.warn(error.response.data)
                }).finally(() => {
                    this.loaders.general = false
                })
        },
        /**
         * Connect echo pusher for check order state
         */
        connectPayEchoPusher() {
            window.Pusher = require("pusher-js");
            window.Echo = new Echo({
                broadcaster: 'pusher',
                cluster: 'eu',
                app_id: '1488181',
                encrypted: true,
                forceTLS: true,
                key: '4564fcfbff8521266486',
                secret: '359d3d58b63ecd4b6faf',
                authEndpoint: this.$config.API_URL_V1 + 'broadcasting/auth',
            });

            let channel = window.Echo.channel('transactions.' + this.payData.id);
            const self = this
            channel.listen('.transaction.changed', function(data) {
                const echoData = JSON.parse(JSON.stringify(data))
                console.warn('event transaction.changed paymentData', data);

                self.paymentData = echoData
                if (self.refInAppBrowser) self.refInAppBrowser.close()

                self.$nextTick(() => self.leaveEcho())

                // const echoData = JSON.parse(JSON.stringify(data))
                // if (echoData.status === 2) self.refInAppBrowser.close()
            });
        },
        leaveEcho() {
            if (window.Echo) window.Echo.leave('transactions.' + this.payData.id)
            console.warn('payment echo leave')
        },
        scrollToPosition() {
            this.$emit('scroll-to-position')
        }
    },
    computed: {
        displaySelectedStep() {
            return step => step === this.orderStep
        },
        canGoBack() {
            return !!(this.displaySelectedStep(1) || this.displaySelectedStep(2) || this.displaySelectedStep(3));
        }
    }
}
</script>

<style lang="scss" scoped>
$primary: #01A6AE;

.create-new-order::v-deep {
    padding: 0 20px;

    ion-list {
        contain: unset;
    }

    .header-wrapper {
        .title {
            margin: 20px 0;

            font-size: 25px;
            font-weight: 600;
            text-align: center;
        }
    }

    .global-form-wrapper {
        .input-wrapper {
            width: 100%;
            padding: 5px 0;

            &.input-background-gray {
                position: relative;
                margin-bottom: 12px;
                z-index: 10;

                &::after {
                    position: absolute;
                    content: '';
                    width: calc(100% + 20px);
                    height: 110%;
                    top: 0;
                    left: -10px;
                    z-index: -1;

                    border-radius: 5px 5px;
                    background: #f5f5f5;
                }

                //padding: 15px;
                //margin: 0 -15px 0 -15px;
                //border-radius: 5px;
                //background: #f5f5f5;
            }

            .label {
                font-size: 13px;
                font-weight: 600;
            }

            .input-container {
                .input-icon {
                    position: absolute;
                    top: 50%;
                    transform: translate(10px, -50%);
                    z-index: 1;

                    max-width: 15px;
                    height: auto;

                    font-size: 13px;
                }

                .input {
                    width: 100%;
                    height: 42px;
                    padding: 3px 10px;

                    outline: 0;
                    font-size: 15px;
                    border-radius: 4px;
                    border: 1px solid var(--ion-color-border-gray);
                    background: white;

                    &--with-icon {
                        padding-left: 30px;
                    }
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    border: 1px solid var(--ion-color-border-gray) !important;
                    -webkit-text-fill-color: black !important;
                }

                .textarea {
                    width: 100%;
                    height: 42px;
                    //min-height: 42px;
                    padding: 3px 10px;

                    outline: 0;
                    font-size: 15px;
                    border-radius: 4px;
                    border: 1px solid var(--ion-color-border-gray);
                    background: white;
                }
            }

            .error-message {
                margin-top: -2px;

                color: red;
                font-size: 14px;
            }
        }

        .checkbox-wrapper {
            display: flex;
            align-items: center;
            gap: 7px;
            margin-top: 10px;

            padding-left: 3px;

            input[type='checkbox'] {
                accent-color: $primary;
                margin-top: -1px;
                transform: scale(1.4);
            }
        }
    }

    .datetime-wrapper {
        display: flex;
        gap: 10px;

        .dp__main {
            width: 100%;
            min-height: 42px;

            .dp__input {
                min-height: 42px;
            }

            .dp__input_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 5px 0 10px;
            }
        }
    }

    .select {
        height: 26px;

        text-align: right;
        border: 1px solid white;
        outline: none;
        background: white;
    }

    // component vue tel input
    .vue-tel-input {
        width: 100%;
        min-height: 42px;

        background: white;
        border-radius: 4px !important;
        border-color: var(--ion-color-border-gray) !important;

        &:focus-within {
            box-shadow: none !important;
            border-color: var(--ion-color-border-gray) !important;
        }

        .vti__dropdown {
            display: none;
        }

        .vti__input {
            border-radius: inherit;
            background: white;
            padding-left: 10px;
        }
    }

    .errors {
        .error-message {
            text-align: center;
            color: red;
        }
    }
}
</style>